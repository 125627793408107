* {
  margin: 0;
  padding: 0; }

a,
a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

img,
iframe,
video {
  max-width: 100%; }

table {
  width: 100%; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

ol,
ul {
  list-style: none; }

fieldset {
  border: 2px groove threedface;
  display: block;
  padding-bottom: 0.75em;
  padding-top: 0.35em;
  text-align: center; }

fieldset legend {
  display: inline-block;
  color: inherit;
  background: none;
  vertical-align: top; }

::-webkit-selection {
  background: #1D1D1D;
  color: #ffffff; }

::-moz-selection {
  background: #1D1D1D;
  color: #ffffff; }

::selection {
  background: #1D1D1D;
  color: #ffffff; }

::-webkit-input-placeholder {
  -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

input::-moz-placeholder {
  -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

input::ms-input-placeholder {
  -webkit-transition: text-indent 0.5s ease, color 0.5s ease;
  transition: text-indent 0.5s ease, color 0.5s ease;
  color: #969696; }

[placeholder]:focus::-webkit-input-placeholder {
  text-indent: 10em;
  color: transparent; }

[placeholder]:focus::-moz-placeholder {
  text-indent: 10em;
  color: transparent; }

body,
html {
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6C6C6C;
  background: #ffffff;
  font-size: 14px;
  overflow-x: hidden; }
  @media (max-width: 991px) {
    body,
    html {
      font-size: 12px; } }

.body--furla {
  font-family: "Avenir", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.title {
  font-family: "DINCondensed-Bold", cursive; }

.color-main {
  color: #F3B612; }

.form-control,
button,
input,
select {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  outline: none !important;
  box-shadow: none;
  cursor: pointer; }
  .form-control:hover, .form-control:focus, .form-control:active,
  button:hover,
  button:focus,
  button:active,
  input:hover,
  input:focus,
  input:active,
  select:hover,
  select:focus,
  select:active {
    outline: none !important;
    box-shadow: none !important;
    border-color: #F3B612; }

/*custom Scroll Chrome */
::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px; }

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(105, 105, 105, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5); }

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(105, 105, 105, 0.4); }

.slick-arrow {
  background: none;
  border: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  font-size: 0;
  background-repeat: no-repeat;
  background-position: center center;
  color: #ffffff; }
  .slick-arrow:hover {
    opacity: 0.8; }

.slick-prev {
  left: 0; }

.slick-next {
  right: 0; }

.slick-dots {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  margin-top: 20px; }
  .slick-dots button {
    font-size: 0;
    width: 5px;
    height: 5px;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 100%;
    background: #aeaeae;
    margin: 5px; }
  .slick-dots .slick-active button {
    width: 7px;
    height: 7px;
    background: #F3B612;
    border: 1px solid #F3B612; }

/* Bootstrap Overrides */
@media (min-width: 1024px) {
  .container {
    max-width: 1024px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

@media (min-width: 992px) {
  .col-lg-5th {
    -webkit-box-flex: 0;
            flex: 0 0 20%;
    max-width: 20%; } }

body {
  padding-right: 0px !important; }

body {
  min-height: 100vh; }

.color_main {
  color: #F3B612; }

.slick-slide {
  outline: none; }
  .slick-slide:focus, .slick-slide:active, .slick-slide:hover {
    outline: none; }

.main-title {
  font-family: "DINCondensed-Bold";
  font-size: 4em;
  line-height: 1em;
  color: #212121;
  margin-bottom: 0; }

.main-btn {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-weight: bold;
  font-size: 1em;
  line-height: 160%;
  height: 3.57143em;
  color: #ffffff;
  min-width: 194px;
  text-align: center;
  background: none;
  position: relative;
  border: none; }
  .main-btn__text {
    position: relative;
    z-index: 5; }
  .main-btn:hover {
    color: #212121; }
    .main-btn:hover::before {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0; }
    .main-btn:hover:after {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1; }
  .main-btn::before, .main-btn:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .main-btn::before {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #212121; }
  .main-btn:after {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    background-color: transparent;
    border: 1px solid #212121; }
  .main-btn--yellow {
    color: #ffffff; }
    .main-btn--yellow .main-btn__text {
      color: #ffffff; }
    .main-btn--yellow::before {
      background-color: #F3B612; }
    .main-btn--yellow::after {
      border-color: #F3B612; }
    .main-btn--yellow:hover {
      color: #F3B612; }
      .main-btn--yellow:hover .main-btn__text {
        color: #F3B612; }

.main-label {
  font-size: 0.85714em;
  line-height: 1.25em;
  color: #212121;
  letter-spacing: 0.35em;
  margin-bottom: 0;
  position: relative; }
  .main-label::before {
    content: '';
    width: 5.66667em;
    height: 1px;
    background-color: #212121;
    position: absolute;
    top: 6px;
    left: -93px; }
  .main-label.yellow::before {
    background-color: #F3B612; }
  .main-label__text-yellow {
    color: #F3B612 !important; }
    .main-label__text-yellow::before {
      content: '';
      width: 5.66667em;
      height: 1px;
      background-color: #F3B612;
      position: absolute;
      top: 6px;
      left: -93px; }
  .main-label--line-through {
    display: inline-block;
    position: relative;
    padding: 0 2.5em; }
    .main-label--line-through::before {
      width: 100%;
      left: 0; }
    .main-label--line-through .main-label__text {
      display: inline-block;
      background-color: #ffffff;
      position: relative;
      z-index: 5;
      padding: 0 1.66667em; }
    .main-label--line-through .text-yellow {
      color: #F3B612 !important; }
  .main-label--no-line::before {
    display: none; }
  @media (max-width: 768px) {
    .main-label {
      padding-left: 45px; }
      .main-label::before {
        width: 30px;
        left: 0; }
      .main-label--line-through {
        padding: 0 2.5em; }
        .main-label--line-through::before {
          width: 100%;
          left: 0; }
        .main-label--line-through .main-label__text {
          padding: 0 1.66667em; }
      .main-label--no-line {
        padding: 0; } }

.main-title-page {
  font-weight: bold;
  font-size: 8.57143em;
  font-family: "DINCondensed-Bold";
  line-height: 1em;
  color: #212121; }
  @media (max-width: 991px) {
    .main-title-page {
      font-size: 4.28571em; } }

.section-info {
  padding: 7.14286em 0; }
  .section-info .row-custom {
    margin: 0 -54px; }
  .section-info .col-custom {
    padding: 0 54px; }
  .section-info__shape {
    position: relative; }
    .section-info__shape--right-shadow .section-info__shadow {
      top: -15px;
      right: -15px; }
  .section-info__img {
    width: 100%;
    position: relative;
    z-index: 10; }
  .section-info__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #F3B612;
    z-index: 5; }
  .section-info__desc {
    margin-bottom: 0;
    font-size: 1.14286em;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #7C7C7C;
    margin-bottom: 0; }
  .section-info__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    background: none;
    outline: none;
    z-index: 15;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 5.35714em;
    height: 5.35714em;
    display: -webkit-box;
    display: flex;
    border: 0.35714em solid #ffffff;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    border-radius: 50%; }
    .section-info__btn svg {
      position: relative;
      left: 3px; }

.section-page {
  position: relative; }
  .section-page::before {
    content: '';
    width: 1px;
    height: 3.57143em;
    position: absolute;
    left: 50%; }
  .section-page--white::before {
    background-color: #212121; }
  .section-page--black::before {
    background-color: #ffffff; }
  .section-page--bottom::before {
    bottom: 0; }
  .section-page--top::before {
    top: 0; }

.geox__page {
  font-family: "Cabin"; }

.flip_card {
  background-color: transparent;
  width: 100%;
  height: 200px;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  .flip_card__inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d; }
  .flip_card:hover .flip_card__inner {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
  .flip_card__front, .flip_card__back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
  .flip_card__front {
    background: #131313; }
  .flip_card__back {
    background: #131313;
    color: #ffffff;
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg); }
    .flip_card__back img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; }

.steenify__page--geox .main-title {
  font-weight: bold;
  font-size: 2.14286em;
  line-height: 1em;
  color: #02274B;
  border-bottom: 3px solid #02274B;
  display: inline-block;
  margin-bottom: 0;
  font-family: "Cabin";
  padding-bottom: 8px; }

.steenify__page--geox .main-btn {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  font-weight: bold;
  font-size: 1.14286em;
  line-height: 1.625em;
  font-family: "Cabin";
  letter-spacing: 0.03em;
  color: #02274B;
  height: 3.4375em;
  border: 1px solid #02274B;
  min-width: 185px;
  text-align: center;
  -webkit-box-pack: center;
          justify-content: center; }
  .steenify__page--geox .main-btn::before, .steenify__page--geox .main-btn::after {
    display: none; }

.shooz__page {
  font-family: "Montserrat"; }

.steenify__page--shooz .main-label {
  color: #434343;
  display: inline-block; }
  .steenify__page--shooz .main-label::before {
    background-color: #434343;
    left: auto;
    right: 0; }
  .steenify__page--shooz .main-label__text {
    padding: 0 7.83333em 0 0; }

.steenify__page--shooz .main-title {
  font-family: "Montserrat";
  color: #1D1D1D;
  font-size: 2.14286em;
  line-height: 1.1em;
  font-weight: bold; }

.steenify__page--shooz .main-btn {
  color: #ffffff; }
  .steenify__page--shooz .main-btn::before {
    opacity: 1;
    -webkit-transform: skew(10deg);
            transform: skew(10deg); }
  .steenify__page--shooz .main-btn:after {
    opacity: 1;
    -webkit-transform: skew(10deg);
            transform: skew(10deg);
    top: 4px;
    left: -4px; }

.steenify__page--shooz .store-location__head {
  display: block; }

.steenify__page--shooz .store-location__form {
  width: 100%;
  max-width: 760px; }
  .steenify__page--shooz .store-location__form .main-btn {
    background-color: transparent; }

.steenify__page--shooz .store-location .main-title {
  color: #FBE106;
  margin-bottom: 0.53333em; }

.steenify__page--geox .store-location .main-btn:hover {
  background-color: #02274B;
  color: #ffffff; }

.steenify__page--furla .store-location {
  background-color: #F1F1F1;
  padding-top: 6.07143em;
  padding-bottom: 5.71429em; }
  .steenify__page--furla .store-location__head {
    flex-wrap: wrap; }
  .steenify__page--furla .store-location .main-title {
    width: 100%;
    margin-bottom: 0.58929em; }
  .steenify__page--furla .store-location .main-label {
    margin-bottom: 2.08333em; }
  .steenify__page--furla .store-location .results {
    background-color: #F8F7F5; }
    .steenify__page--furla .store-location .results .item__name {
      font-weight: 600;
      color: #1D1D1D; }
    .steenify__page--furla .store-location .results .item__info {
      color: #606060; }

@media (max-width: 768px) {
  .steenify__page--furla .store-location .main-title {
    margin-bottom: 0; } }

.wpcf7-response-output {
  display: none !important; }

@font-face {
  font-family: "DINCondensed-Bold";
  src: url("/wp-content/themes/steen/app/public/font/DINCondensed-Bold.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/DINCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/DINCondensed-Bold.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/DINCondensed-Bold.svg#ywftsvg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Roman.eot");
  src: url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Roman.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Roman.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Roman.svg#ywftsvg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Medium.eot");
  src: url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Medium.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Medium.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Medium.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Medium.svg#ywftsvg") format("svg");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: "Avenir";
  src: url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Book.eot");
  src: url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Book.woff") format("woff"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Book.ttf") format("truetype"), url("/wp-content/themes/steen/app/public/font/AvenirLTStd-Book.svg#ywftsvg") format("svg");
  font-weight: 900;
  font-style: normal; }

.top-bar {
  font-size: 0.71429em;
  line-height: 160%;
  background-color: #1D1D1D;
  color: #ffffff;
  padding: 1em 0 0.9em 0;
  display: none; }

.header {
  position: relative;
  z-index: 100; }
  .header .logo img {
    max-width: 245px; }
  .header__toggle {
    position: absolute;
    top: 50%;
    left: 15px;
    background: none;
    border: none;
    outline: none;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .header--main {
    height: 6.42857em; }
    .header--main .header__body {
      position: fixed;
      width: 100%;
      height: 6.42857em;
      top: 0;
      left: 0;
      background-color: #ffffff; }
    .header--main .header__inner {
      height: 6.42857em;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: justify;
              justify-content: space-between; }
    .header--main .main-menu {
      margin: 0;
      font-family: "DINCondensed-Bold";
      font-size: 1.21429em;
      line-height: 1.17647em;
      letter-spacing: 0.05em; }
      .header--main .main-menu .item {
        display: inline-block;
        margin-left: 2.23529em;
        position: relative; }
        .header--main .main-menu .item:first-child {
          margin-left: 0; }
        .header--main .main-menu .item:hover .sub-menu {
          opacity: 1;
          height: auto;
          overflow: unset;
          visibility: visible;
          top: 0; }
        .header--main .main-menu .item__link {
          color: #212121;
          position: relative;
          z-index: 1;
          cursor: pointer; }
          .header--main .main-menu .item__link:hover {
            color: #F3B612; }
        .header--main .main-menu .item .sub-menu {
          position: absolute;
          top: 20px;
          left: -27px;
          background-color: #ffffff;
          min-width: 129px;
          padding: 43px 25px 0 25px;
          opacity: 0;
          visibility: hidden;
          height: 0;
          overflow: hidden;
          -webkit-transition: all .5s ease;
          transition: all .5s ease; }
          .header--main .main-menu .item .sub-menu .item-sub {
            margin-bottom: 18px; }
            .header--main .main-menu .item .sub-menu .item-sub__link {
              color: #212121; }
              .header--main .main-menu .item .sub-menu .item-sub__link:hover {
                color: #F3B612; }
          .header--main .main-menu .item .sub-menu .active {
            color: #F3B612; }
            .header--main .main-menu .item .sub-menu .active .item-sub__link {
              color: #F3B612; }
        .header--main .main-menu .item .wpml-ls-legacy-dropdown {
          width: 3.21429em;
          margin-top: -0.71429em; }
          .header--main .main-menu .item .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
            border-top: none; }
          .header--main .main-menu .item .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover a {
            background: none; }
          .header--main .main-menu .item .wpml-ls-legacy-dropdown .wpml-ls-current-language a {
            background: none;
            border: none;
            padding: 5px 0;
            display: inline-block; }
            .header--main .main-menu .item .wpml-ls-legacy-dropdown .wpml-ls-current-language a:hover {
              border: none;
              background: none; }
            .header--main .main-menu .item .wpml-ls-legacy-dropdown .wpml-ls-current-language a:after {
              position: unset;
              top: auto;
              right: auto;
              left: auto;
              margin-left: 5px; }
          .header--main .main-menu .item .wpml-ls-legacy-dropdown .wpml-ls-flag {
            width: 29px;
            height: 21px; }
      .header--main .main-menu .language-mobile {
        display: none; }
      .header--main .main-menu .active {
        color: #F3B612; }
        .header--main .main-menu .active .item__link {
          color: #F3B612; }
    .header--main .language_desktop .wpml-ls-legacy-dropdown {
      width: 3.21429em;
      margin-top: -0.71429em; }
      .header--main .language_desktop .wpml-ls-legacy-dropdown .wpml-ls-sub-menu {
        border-top: none; }
      .header--main .language_desktop .wpml-ls-legacy-dropdown .wpml-ls-current-language:hover a {
        background: none; }
      .header--main .language_desktop .wpml-ls-legacy-dropdown .wpml-ls-current-language a {
        background: none;
        border: none;
        padding: 5px 0;
        display: inline-block; }
        .header--main .language_desktop .wpml-ls-legacy-dropdown .wpml-ls-current-language a:hover {
          border: none;
          background: none; }
        .header--main .language_desktop .wpml-ls-legacy-dropdown .wpml-ls-current-language a:after {
          position: unset;
          top: auto;
          right: auto;
          left: auto;
          margin-left: 5px; }
      .header--main .language_desktop .wpml-ls-legacy-dropdown .wpml-ls-flag {
        width: 29px;
        height: 21px; }
    .header--main.scrolled .header__body, .header--main.scrolled .header__inner {
      height: 4em;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    @media (max-width: 991px) {
      .header--main .header__inner {
        -webkit-box-pack: center;
                justify-content: center;
        position: relative; }
      .header--main .language_desktop {
        display: none; }
      .header--main .main-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 12;
        display: none;
        background: #ffffff;
        padding: 15px; }
        .header--main .main-menu .item {
          display: block;
          margin: 0;
          margin-bottom: 15px; }
          .header--main .main-menu .item__link {
            display: block; }
          .header--main .main-menu .item .sub-menu {
            position: relative;
            top: 0;
            left: auto;
            width: 100%;
            height: auto;
            visibility: visible;
            padding-left: 30px;
            opacity: 1;
            padding-top: 0; }
            .header--main .main-menu .item .sub-menu .item-sub {
              margin-bottom: 0;
              margin-top: 15px; } }
    @media (max-width: 576px) {
      .header--main .header__body {
        position: fixed !important; }
      .header--main .header__inner {
        position: static;
        position: initial; } }
  .header--furla .header__inner {
    padding: 2.14286em 0 1.21429em 0;
    text-align: center; }
  .header--furla .logo {
    display: inline-block;
    margin-bottom: 29px;
    max-width: 158px; }
  .header--furla .main-menu {
    font-weight: 800;
    font-size: 0.85714em;
    line-height: 1.33333em;
    -webkit-text-decoration: uppercase;
            text-decoration: uppercase;
    margin-bottom: 0;
    font-family: "Avenir"; }
    .header--furla .main-menu .item {
      display: inline-block;
      margin: 0 1.25em; }
      .header--furla .main-menu .item.active .item__link::after {
        bottom: -3px;
        opacity: 1; }
      .header--furla .main-menu .item__link {
        color: #333333;
        position: relative; }
        .header--furla .main-menu .item__link:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: #333333;
          position: absolute;
          bottom: 3px;
          opacity: 0;
          left: 0;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        .header--furla .main-menu .item__link:hover:after {
          bottom: -3px;
          opacity: 1; }
  @media (max-width: 991px) {
    .header--furla .header__inner {
      -webkit-box-pack: center;
              justify-content: center;
      position: relative;
      padding: 5px 0; }
    .header--furla .logo {
      margin-bottom: 0; }
    .header--furla .main-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 12;
      display: none;
      background: #ffffff;
      padding: 15px; }
      .header--furla .main-menu .item {
        display: block;
        margin: 0;
        margin-bottom: 15px; }
        .header--furla .main-menu .item__link {
          display: inline-block; } }
  @media (max-width: 576px) {
    .header--furla .header__body {
      position: relative; }
    .header--furla .header__inner {
      position: static;
      position: initial; } }
  .header--geox .header__inner {
    padding: 1.57143em 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between; }
  .header--geox .main-menu {
    font-weight: normal;
    font-size: 0.92857em;
    line-height: 1.23077em;
    margin-bottom: 0; }
    .header--geox .main-menu .item {
      display: inline-block;
      margin-left: 3.38462em; }
      .header--geox .main-menu .item:first-child {
        margin-left: 0; }
      .header--geox .main-menu .item__link {
        color: #212121;
        letter-spacing: 0.02em; }
  @media (max-width: 991px) {
    .header--geox .header__inner {
      -webkit-box-pack: center;
              justify-content: center;
      position: relative; }
    .header--geox .main-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 12;
      display: none;
      background: #ffffff;
      padding: 15px; }
      .header--geox .main-menu .item {
        display: block;
        margin: 0;
        margin-bottom: 15px; }
        .header--geox .main-menu .item__link {
          display: block; } }
  @media (max-width: 576px) {
    .header--geox .header__body {
      position: relative; }
    .header--geox .header__inner {
      position: static;
      position: initial; } }
  .header--shooz .header__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
    height: 7.14286em; }
  .header--shooz .logo {
    display: inline-block;
    max-width: 10.64286em; }
  .header--shooz .main-menu {
    font-weight: normal;
    font-size: 0.92857em;
    line-height: 1.23077em;
    margin-bottom: 0; }
    .header--shooz .main-menu .item {
      display: inline-block;
      margin-left: 2.69231em; }
      .header--shooz .main-menu .item:first-child {
        margin-left: 0; }
      .header--shooz .main-menu .item__link {
        color: #212121; }
  @media (max-width: 991px) {
    .header .header__inner {
      -webkit-box-pack: center;
              justify-content: center;
      position: relative; }
    .header .main-menu {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 12;
      display: none;
      background: #ffffff;
      padding: 15px; }
      .header .main-menu .item {
        display: block;
        margin: 0;
        margin-bottom: 15px; }
        .header .main-menu .item__link {
          display: block; }
    .header--main {
      height: 77px; }
      .header--main .header__body, .header--main .header__inner {
        height: 77px; }
      .header--main .main-menu {
        position: fixed;
        width: 100%;
        top: 77px; }
      .header--main.scrolled .header__body, .header--main.scrolled .header__inner {
        height: 77px;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; } }
  @media (max-width: 576px) {
    .header .header__body {
      position: relative; }
    .header .header__inner {
      position: static;
      position: initial; } }
  @media (max-width: 400px) {
    .header--main .logo {
      display: inline-block;
      max-width: 200px; } }

.burger {
  position: relative;
  width: 25px;
  height: 20px;
  z-index: 3; }
  .burger .rect {
    width: 25px;
    height: 2px;
    will-change: opacity, transform, background-color;
    -webkit-transition: opacity 0.3s ease, background-color 0.3s ease, -webkit-transform 0.6s ease;
    transition: opacity 0.3s ease, background-color 0.3s ease, -webkit-transform 0.6s ease;
    transition: transform 0.6s ease, opacity 0.3s ease, background-color 0.3s ease;
    transition: transform 0.6s ease, opacity 0.3s ease, background-color 0.3s ease, -webkit-transform 0.6s ease;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-color: #161718;
    -webkit-transform: rotate(0);
    transform: rotate(0); }
    .burger .rect:first-of-type {
      position: absolute;
      top: 18px;
      left: 0; }
    .burger .rect:nth-of-type(2) {
      display: none; }
    .burger .rect:nth-of-type(2), .burger .rect:nth-of-type(3) {
      position: absolute;
      top: 9px;
      left: 0; }
    .burger .rect:nth-of-type(4) {
      position: absolute;
      top: 0;
      left: 0; }
  .burger.opened .rect:first-of-type {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }
  .burger.opened .rect:nth-of-type(2) {
    display: inline;
    -webkit-transform: rotate(-225deg);
    transform: rotate(-225deg);
    background-color: #161718; }
  .burger.opened .rect:nth-of-type(3) {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    background-color: #161718; }
  .burger.opened .rect:nth-of-type(4) {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }

.footer--main {
  color: #ffffff;
  background-color: #494949;
  font-size: 1.07143em;
  line-height: 1.26667em;
  padding-top: 3.53333em;
  padding-bottom: 1.33333em; }
  .footer--main .logo {
    width: 269px; }
  .footer--main .footer__inner {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between; }
  .footer--main .footer__menu {
    display: -webkit-box;
    display: flex;
    margin: 0 -1.86667em;
    width: calc(100% - 269px - 60px); }
  .footer--main .listlinks {
    padding: 0 1.66667em;
    min-width: 150px; }
    .footer--main .listlinks__title {
      margin: 2px 0 0 0;
      font-weight: bold; }
    .footer--main .listlinks__body {
      margin: 1em 0 0 0; }
    .footer--main .listlinks .item {
      margin-bottom: 6px;
      position: relative; }
      .footer--main .listlinks .item__icon {
        position: 0;
        top: 4px;
        left: 0;
        position: absolute; }
      .footer--main .listlinks .item__link {
        color: #ffffff;
        margin-right: 6px; }
        .footer--main .listlinks .item__link:last-child {
          margin-right: 0; }
        .footer--main .listlinks .item__link:hover {
          color: #F3B612; }
    .footer--main .listlinks .active {
      color: #F3B612; }
      .footer--main .listlinks .active .item__link {
        color: #F3B612; }
    .footer--main .listlinks--menu .listlinks__body {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap; }
      .footer--main .listlinks--menu .listlinks__body .item {
        -webkit-box-flex: 0;
                flex: 0 0 50%; }
    .footer--main .listlinks--contact .item {
      padding-left: 1.42857em; }
  .footer--main .copy {
    padding-top: 1.85714em;
    font-size: 0.93333em;
    line-height: 1em;
    width: 100%;
    color: rgba(255, 255, 255, 0.6); }

.footer--geox {
  background-color: #02274a; }

@media (max-width: 1200px) {
  .footer--main .footer__menu {
    width: calc(100% - 269px - 15px); } }

@media (max-width: 991px) {
  .footer--main .footer .logo {
    width: 100%;
    max-width: 269px; }
  .footer--main .footer__menu {
    width: 100%;
    flex-wrap: wrap; }
  .footer--main .listlinks {
    margin-top: 2.14286em;
    width: 50%; }
    .footer--main .listlinks .item__icon {
      top: 2px; } }

@media (max-width: 768px) {
  .footer--main .listlinks {
    width: 100%; }
    .footer--main .listlinks--brands, .footer--main .listlinks--menu {
      width: 50%; }
    .footer--main .listlinks--menu .listlinks__body .item {
      -webkit-box-flex: 0;
              flex: 0 0 100%;
      max-width: 100%; } }

.numbers {
  padding: 2.14286em 0 9.78571em 0; }
  .numbers__inner {
    margin: 0 auto; }
  .numbers .item {
    position: relative;
    padding: 5px 4.42857em; }
    .numbers .item:after {
      content: '';
      width: 1px;
      height: 72px;
      background-color: #F3B612;
      position: absolute;
      bottom: 23px;
      right: 0; }
    .numbers .item__title {
      font-family: "DINCondensed-Bold";
      font-weight: bold;
      font-size: 6.42857em;
      line-height: 1em;
      color: #333333; }
    .numbers .item__desc {
      font-size: 1.28571em;
      line-height: 160%;
      letter-spacing: 0.2em;
      color: #7E7E7E;
      margin-top: -4px; }
    .numbers .item__caption {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%) scale(0.5);
              transform: translateY(-50%) scale(0.5);
      left: 1px;
      background-color: #ffffff;
      width: calc(100% - 2px);
      display: -webkit-box;
      display: flex;
      z-index: 5;
      -webkit-box-align: center;
              align-items: center;
      opacity: 0;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      padding: 0 1.07143em; }
    .numbers .item__col--number {
      width: 100px;
      padding-top: 10px; }
    .numbers .item__col--text {
      width: calc(100% - 100px); }
    .numbers .item__content {
      font-size: 1.07143em;
      line-height: 1.73333em;
      text-align: left; }
    .numbers .item:hover .item__caption {
      opacity: 1;
      -webkit-transform: translateY(-50%) scale(1);
              transform: translateY(-50%) scale(1); }
    .numbers .item:hover .item__title {
      color: #F3B612; }
  .numbers .col-custom:last-child .item::after {
    display: none; }
  @media (max-width: 991px) {
    .numbers .item {
      padding: 0;
      margin-bottom: 50px; }
      .numbers .item:after {
        display: none; }
      .numbers .item__head {
        display: none; }
      .numbers .item__caption {
        opacity: 1;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        -webkit-transform: none;
                transform: none;
        padding: 0; }
      .numbers .item:hover .item__caption {
        -webkit-transform: none;
                transform: none; } }
  @media (max-width: 768px) {
    .numbers {
      padding: 0; } }

.find-shop {
  padding-top: 4.42857em;
  padding-bottom: 5.5em;
  background-color: #f5f5f5; }
  .find-shop__body {
    padding: 0 5.85714em; }
  .find-shop__inner {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
            justify-content: space-between; }
  .find-shop__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end; }
  .find-shop .main-label {
    margin-bottom: 1.58333em; }
  .find-shop .main-btn {
    margin-left: 2em; }
  .find-shop .main-title {
    position: relative;
    top: 8px; }
  .find-shop .form-group {
    position: relative;
    margin-bottom: 0;
    width: 353px;
    border-bottom: 1px solid rgba(33, 33, 33, 0.5); }
  .find-shop .form-icon {
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    margin-top: -25px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: end;
            justify-content: flex-end; }
  .find-shop .form-control {
    display: inline-block;
    border: none;
    border-radius: 0;
    height: 50px;
    padding: 0;
    box-shadow: none;
    background: none;
    outline: none;
    font-weight: bold;
    font-size: 36px;
    font-family: "DINCondensed-Bold";
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    z-index: 5;
    color: #212121; }
    .find-shop .form-control::-ms-expand {
      display: none; }
  @media (max-width: 1024px) {
    .find-shop__form {
      margin-top: 30px; } }
  @media (max-width: 768px) {
    .find-shop {
      padding: 50px 0; }
      .find-shop__body {
        padding: 0; }
      .find-shop__form {
        flex-wrap: wrap;
        margin-top: 30px;
        -webkit-box-flex: 0;
                flex: 0 0 100%;
        max-width: 100%; }
      .find-shop .main-btn {
        margin: 30px auto 0 auto; }
      .find-shop .form-group {
        width: 100%; } }

.newsletter {
  padding-top: 6.14286em;
  padding-bottom: 7.21429em;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
  background-repeat: no-repeat; }
  .newsletter::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 36, 0.9); }
  .newsletter .container {
    position: relative;
    z-index: 5; }
  .newsletter .main-label {
    margin-bottom: 2.5em;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
            align-items: center; }
    .newsletter .main-label::before, .newsletter .main-label:after {
      content: '';
      display: block;
      position: static;
      top: auto;
      left: auto;
      width: 2.08333em;
      height: 1px;
      background-color: #F3B612; }
    .newsletter .main-label__text {
      background-color: transparent; }
  .newsletter .main-title {
    margin-bottom: 0.64286em;
    color: #ffffff; }
  .newsletter .input-group {
    max-width: 591px;
    margin: 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    flex-wrap: nowrap; }
  .newsletter .input-group-append {
    width: 13.85714em;
    flex-wrap: wrap; }
    .newsletter .input-group-append p {
      width: 100%; }
  .newsletter .form-control {
    width: calc(100% - 194px);
    height: 3.46667em;
    border-radius: 0;
    border-color: #cdcdcd;
    font-size: 1.07143em;
    letter-spacing: 0.02em;
    border-right: none;
    padding: 0 1.46667em;
    color: #b0b0b0;
    box-shadow: none;
    outline: none;
    background: none;
    background-color: #ffffff;
    cursor: auto; }
  .newsletter .btn-subscribe {
    width: 100%;
    border: none;
    background: none;
    height: 3.71429em;
    font-weight: bold;
    font-size: 1em;
    line-height: 160%;
    color: #ffffff;
    background-color: #F3B612;
    font-weight: bold;
    position: relative;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
    .newsletter .btn-subscribe:hover {
      background-color: #bb8b0a; }

.wpcf7-form-control-wrap .wpcf7-form-control {
  width: 100%; }

.listing {
  padding-top: 6.35714em;
  padding-bottom: 4.57143em; }
  .listing .main-btn {
    margin-top: -10px; }
  .listing .list {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 2.07143em -10px 0 -10px; }
  .listing .item {
    padding: 0 0.71429em;
    width: 33.33333%;
    margin-bottom: 5.71429em; }
    .listing .item__caption {
      padding: 1.78571em 0 0 0;
      background-color: #ffffff;
      position: relative; }
    .listing .item__image {
      width: 100%;
      height: 288px;
      -o-object-fit: cover;
         object-fit: cover; }
    .listing .item__title {
      font-weight: bold;
      font-size: 2.14286em;
      line-height: 1.2em;
      color: #212121;
      margin-bottom: 8px; }
      .listing .item__title a {
        color: #212121; }
        .listing .item__title a:hover {
          color: #F3B612; }
    .listing .item__desc {
      line-height: 1.57143em;
      letter-spacing: 0.02em;
      color: #212121;
      margin-bottom: 1.5em; }
    .listing .item__link {
      display: inline-block;
      font-weight: bold;
      line-height: 1.28571em;
      letter-spacing: 0.12em;
      position: relative;
      color: #212121;
      padding-bottom: 1em; }
      .listing .item__link:after {
        content: '';
        width: 71px;
        height: 1px;
        background-color: #212121;
        position: absolute;
        left: 0;
        bottom: 0;
        -webkit-transition: width .3s ease;
        transition: width .3s ease; }
      .listing .item__link:hover:after {
        width: 100%; }
    .listing .item--highlight {
      width: 66.66667%; }
      .listing .item--highlight .item__title {
        font-size: 2.85714em;
        line-height: 1.075em;
        margin-bottom: 0.4em; }
      .listing .item--highlight .item__image {
        height: 511px; }
      .listing .item--highlight .item__caption {
        padding: 3.21429em 2.64286em 0 2.64286em;
        margin-top: -12em;
        max-width: 463px; }
    .listing .item--feature .item__caption {
      padding: 1.78571em 1.64286em 0 1.64286em; }
  @media (max-width: 991px) {
    .listing .item {
      width: 50%; }
      .listing .item__image {
        height: 250px; }
      .listing .item--highlight {
        width: 100%; }
        .listing .item--highlight .item__caption {
          margin-top: 0; }
        .listing .item--highlight .item__image {
          height: auto;
          -o-object-fit: unset;
             object-fit: unset; }
      .listing .item--feature {
        width: 50%; } }
  @media (max-width: 576px) {
    .listing {
      padding: 50px 0; }
      .listing .item {
        width: 100%;
        margin-bottom: 50px; }
        .listing .item--feature {
          width: 100%; }
        .listing .item__image {
          height: auto;
          -o-object-fit: unset;
             object-fit: unset; } }

.hero-banner {
  line-height: 0;
  position: relative; }
  .hero-banner__image {
    width: 100%; }
  .hero-banner--geox {
    color: #02274B; }
    .hero-banner--geox .main-btn {
      min-width: 285px;
      background-color: transparent;
      color: #ffffff;
      border-color: #ffffff; }
      .hero-banner--geox .main-btn:hover {
        background-color: #ffffff;
        color: #212121; }
    .hero-banner--geox .hero-banner__caption {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center; }
      .hero-banner--geox .hero-banner__caption::before {
        content: '';
        width: 100%;
        height: 100%;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0; }
      .hero-banner--geox .hero-banner__caption .container {
        z-index: 1; }
    .hero-banner--geox .hero-banner__inner {
      max-width: 488px; }
    .hero-banner--geox .hero-banner__title {
      font-size: 3.21429em;
      line-height: 1em;
      font-weight: bold;
      margin-bottom: 0.37778em;
      color: #ffffff; }
    .hero-banner--geox .hero-banner__desc {
      font-size: 1.14286em;
      line-height: 1.3125em;
      margin-bottom: 2.5em;
      color: #ffffff; }
    .hero-banner--geox .hero-banner__logo {
      max-width: 100%;
      margin-bottom: 2.85714em;
      width: 10.78571em; }
  .hero-banner--shooz .hero-banner__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center; }
    .hero-banner--shooz .hero-banner__caption::before {
      content: '';
      width: 100%;
      height: 100%;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0; }
    .hero-banner--shooz .hero-banner__caption .container {
      position: relative;
      z-index: 1; }
  .hero-banner--shooz .hero-banner__logo {
    display: block;
    margin-bottom: 1.42857em;
    width: 12.85714em; }
  .hero-banner--shooz .hero-banner__itext {
    display: block;
    width: 34.5%;
    min-width: 200px;
    margin-left: 0.92857em; }
  .hero-banner--shooz .hero-banner__title {
    font-size: 5em;
    line-height: 1.04286em;
    color: #ffffff;
    max-width: 594px;
    font-family: "Montserrat";
    font-weight: 800; }
  .hero-banner--gosumo {
    color: #ffffff; }
    .hero-banner--gosumo .main-title {
      color: #ffffff;
      font-size: 5em;
      line-height: 1em;
      margin-bottom: 0.55714em; }
    .hero-banner--gosumo .main-label {
      color: #ffffff;
      font-weight: 600;
      font-size: 1.07143em;
      line-height: 1.26667em;
      margin-bottom: 2.8em; }
      .hero-banner--gosumo .main-label::before {
        background-color: #ffffff; }
    .hero-banner--gosumo .main-btn {
      color: #212121; }
      .hero-banner--gosumo .main-btn:before {
        background-color: #ffffff; }
      .hero-banner--gosumo .main-btn:after {
        border-color: #ffffff; }
      .hero-banner--gosumo .main-btn:hover {
        color: #ffffff; }
    .hero-banner--gosumo .hero-banner__caption {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center; }
      .hero-banner--gosumo .hero-banner__caption::before {
        content: '';
        width: 100%;
        height: 100%;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0; }
      .hero-banner--gosumo .hero-banner__caption .container {
        position: relative;
        z-index: 1; }
    .hero-banner--gosumo .hero-banner__logo {
      margin-bottom: 2.85714em;
      width: 15.28571em; }
  @media (max-width: 991px) {
    .hero-banner--geox {
      font-size: 12px; }
      .hero-banner--geox .hero-banner__image {
        height: 544px;
        -o-object-fit: cover;
           object-fit: cover; }
      .hero-banner--geox .hero-banner__logo {
        width: 102px;
        top: 26px;
        left: 16px;
        position: absolute; }
      .hero-banner--geox .hero-banner__title {
        font-size: 28px;
        color: #ffffff; }
      .hero-banner--geox .hero-banner__desc {
        color: #ffffff;
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 30px; }
      .hero-banner--geox .hero-banner__caption {
        -webkit-box-align: end;
                align-items: flex-end;
        padding-bottom: 50px; }
        .hero-banner--geox .hero-banner__caption::before {
          content: '';
          width: 100%;
          height: 100%;
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          opacity: 0.7;
          position: absolute;
          top: 0;
          left: 0; }
        .hero-banner--geox .hero-banner__caption .container {
          z-index: 1; }
      .hero-banner--geox .main-btn {
        background-color: transparent;
        color: #ffffff;
        border-color: #ffffff;
        min-width: 278px;
        font-size: 16px; }
        .hero-banner--geox .main-btn:hover {
          background-color: #ffffff;
          color: #212121; }
    .hero-banner--shooz {
      position: relative;
      height: 544px; }
      .hero-banner--shooz .hero-banner__image {
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
      .hero-banner--shooz .hero-banner__logo {
        width: 108px; }
      .hero-banner--shooz .hero-banner__title {
        font-size: 40px;
        color: #ffffff; }
      .hero-banner--shooz .hero-banner__caption {
        -webkit-box-align: end;
                align-items: flex-end;
        padding-bottom: 59px; }
        .hero-banner--shooz .hero-banner__caption::before {
          content: '';
          width: 100%;
          height: 100%;
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          opacity: 0.7;
          position: absolute;
          top: 0;
          left: 0; }
        .hero-banner--shooz .hero-banner__caption .container {
          position: relative;
          z-index: 1; }
      .hero-banner--shooz .hero-banner__logo {
        position: absolute;
        top: 23px;
        left: 10px; }
    .hero-banner--gosumo {
      height: 544px; }
      .hero-banner--gosumo .main-label {
        font-size: 0.85714em; }
      .hero-banner--gosumo .main-title {
        font-size: 50px;
        line-height: 50px;
        color: #ffffff;
        margin-bottom: 20px; }
      .hero-banner--gosumo .main-btn {
        height: 48px; }
      .hero-banner--gosumo .hero-banner__image {
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
      .hero-banner--gosumo .hero-banner__logo {
        width: 128px;
        position: absolute;
        top: 26px;
        left: 16px; }
      .hero-banner--gosumo .hero-banner__caption {
        -webkit-box-align: end;
                align-items: flex-end;
        padding-bottom: 56px; }
        .hero-banner--gosumo .hero-banner__caption::before {
          content: '';
          width: 100%;
          height: 100%;
          background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          opacity: 0.7;
          position: absolute;
          top: 0;
          left: 0; }
        .hero-banner--gosumo .hero-banner__caption .container {
          position: relative;
          z-index: 1; } }

.shop-now {
  background-repeat: none;
  background-size: cover;
  background-attachment: fixed; }
  .shop-now--geox .main-btn {
    background-color: #ffffff;
    color: #02274B;
    border-color: #ffffff; }
    .shop-now--geox .main-btn:hover {
      background-color: #02274B;
      color: #ffffff;
      border-color: #02274B; }
  .shop-now--geox .shop-now__inner {
    height: 366px;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
    color: #ffffff; }
  .shop-now--geox .shop-now__content {
    max-width: 465px; }
  .shop-now--geox .shop-now__title {
    font-weight: bold;
    font-size: 3.21429em;
    line-height: 1em;
    margin-bottom: 0.22222em; }
  .shop-now--geox .shop-now__desc {
    font-size: 1.35714em;
    line-height: 1.21053em;
    letter-spacing: 0.02em;
    margin-bottom: 0; }
  .shop-now--shooz {
    height: 334px;
    background-color: #FBE106;
    background-attachment: unset;
    background-size: auto;
    background-position: top right;
    background-repeat: no-repeat;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .shop-now--shooz .main-title {
      margin-bottom: 0.5em; }
    .shop-now--shooz .shop-now__desc {
      font-size: 1.42857em;
      line-height: 1.3em;
      color: #000000;
      margin-bottom: 1.85em; }
    .shop-now--shooz .shop-now__content {
      max-width: 695px; }
  @media (max-width: 991px) {
    .shop-now--geox {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      height: 336px; }
      .shop-now--geox .main-btn {
        margin-top: 30px; }
      .shop-now--geox .shop-now__inner {
        display: block;
        height: auto; }
      .shop-now--geox .shop-now__content {
        width: 100%;
        max-width: 100%; } }
  @media (max-width: 768px) {
    .shop-now--shooz {
      background-image: none !important; } }

.store-location {
  padding-top: 5.35714em;
  padding-bottom: 4.64286em; }
  .store-location__head {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 4.5em; }
  .store-location__form {
    width: 62.25%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
    padding-right: 210px;
    position: relative;
    height: 55px; }
    .store-location__form .main-btn {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #3963C0;
      color: #ffffff;
      border: none; }
  .store-location .form-group {
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 1px solid #000000;
    position: relative; }
    .store-location .form-group--1 {
      width: 55.86592%; }
    .store-location .form-group--2 {
      width: 39.47858%; }
    .store-location .form-group .icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
  .store-location .form-control {
    padding: 0 2.85714em 0 1.42857em;
    border: none;
    font-size: 1em;
    font-family: "Open Sans";
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    z-index: 5;
    background-color: transparent; }
    .store-location .form-control::-ms-expand {
      display: none; }
  .store-location .map-search {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end; }
  .store-location .map {
    width: 75%;
    height: 512px; }
  .store-location .results {
    width: 29.91667%;
    height: 448px;
    position: absolute;
    left: 0;
    z-index: 5;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #F1F5F7;
    padding: 4px 3em;
    overflow-y: auto; }
    .store-location .results .item {
      padding-top: 1.42857em;
      padding-bottom: 1.57143em;
      border-top: 1px solid #CCCCCC; }
      .store-location .results .item:first-child {
        border-top: none; }
      .store-location .results .item__name {
        font-weight: bold;
        font-size: 1.14286em;
        line-height: 1.625em;
        letter-spacing: 0.02em;
        color: #02274B;
        margin-bottom: 3px;
        cursor: pointer; }
      .store-location .results .item__info {
        line-height: 1.42857em;
        letter-spacing: 0.03em;
        color: #212121; }
      .store-location .results .item__address {
        margin-bottom: 0; }
      .store-location .results .item__phone {
        margin-bottom: 0; }
  @media (max-width: 991px) {
    .store-location__head {
      flex-wrap: wrap;
      margin-bottom: 30px; }
    .store-location__form {
      width: 100%;
      margin-top: 30px; }
    .store-location .map-search {
      flex-wrap: wrap; }
    .store-location .map {
      width: 100%;
      -webkit-box-ordinal-group: 0;
              order: -1;
      height: 300px; }
    .store-location .results {
      width: 100%;
      position: static;
      top: auto;
      -webkit-transform: none;
              transform: none;
      left: auto;
      margin-top: 30px; } }
  @media (max-width: 768px) {
    .store-location {
      padding: 50px 0; }
      .store-location__form {
        padding-right: 0;
        flex-wrap: wrap;
        height: auto; }
        .store-location__form .main-btn {
          position: relative;
          right: auto;
          top: auto;
          margin: 15px auto 0 auto; }
      .store-location .form-group--1, .store-location .form-group--2 {
        width: calc(50% - 5px); } }

.map-info__name {
  font-weight: bold;
  font-size: 1.14286em;
  line-height: 1.625em;
  letter-spacing: 0.02em;
  color: #02274B;
  margin-bottom: 3px; }

.map-info__info {
  line-height: 1.42857em;
  letter-spacing: 0.03em;
  color: #212121; }

.map-info__address {
  margin-bottom: 0; }

.map-info__phone {
  margin-bottom: 0; }

.gm-style .gm-style-iw-c {
  box-shadow: none;
  border-radius: 0;
  border-bottom: 2px solid #02274B; }

.modal_custom__dialog {
  max-width: 754px; }

.modal_custom__close {
  width: 55px;
  height: 55px;
  background: #212121;
  color: #f8f7f5;
  margin-left: auto;
  border: 1px solid #212121; }
  .modal_custom__close:hover, .modal_custom__close:active, .modal_custom__close:focus {
    border: 1px solid #F3B612;
    background: #F3B612;
    color: #ffffff; }

.modal_custom__content {
  padding: 0 60px;
  border-radius: 0; }

.modal_custom__header {
  border-bottom: none; }
  .modal_custom__header .modal-title {
    font-family: "DINCondensed-Bold";
    font-weight: bold;
    font-size: 55px;
    text-align: center;
    text-transform: uppercase;
    color: #212121; }

.modal_custom__sub {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 0.02em;
  color: #212121;
  max-width: 535px;
  margin: auto; }

.modal_custom__footer {
  border-top: none;
  padding-bottom: 60px; }

.modal_custom__item {
  margin-bottom: 30px; }
  .modal_custom__item label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.6;
    color: #111111;
    margin-bottom: 6px; }
  .modal_custom__item .form-control {
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 0;
    height: 52px;
    font-size: 16px;
    color: #111111; }
  .modal_custom__item textarea {
    height: auto !important; }
  .modal_custom__item select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEyIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDAuNUw2LjE0NzQ2IDUuMzgzNzlMMTEuMTk0MSAwLjUiIHN0cm9rZT0iIzJEMzA1NCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=");
    background-position: 97% center;
    background-repeat: no-repeat; }

@media (max-width: 576px) {
  .modal_custom__content {
    padding: 0px; }
  .modal_custom__header .modal-title {
    font-size: 35px; }
  .modal_custom__body {
    padding-left: 15px !important;
    padding-right: 15px !important; } }

.banner-slider {
  position: relative; }
  .banner-slider .item {
    height: 48.64286em;
    position: relative;
    z-index: 5; }
    .banner-slider .item img {
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      position: relative;
      z-index: 1; }
    .banner-slider .item__label {
      font-size: 0.85714em;
      line-height: 1.25em;
      color: #F3B612;
      letter-spacing: 0.35em;
      margin-bottom: 2.25em;
      position: relative;
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
      opacity: 0;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .banner-slider .item__line {
      display: inline-block;
      width: 0;
      height: 1px;
      background-color: #F3B612;
      position: absolute;
      right: 34px;
      top: 6px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .banner-slider .item__desc {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .banner-slider .item__btn {
      opacity: 0;
      -webkit-transform: translateY(20px);
              transform: translateY(20px);
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
    .banner-slider .item__caption {
      z-index: 5;
      position: absolute;
      width: 100%;
      bottom: 3.57143em;
      right: 0;
      text-align: right;
      text-transform: uppercase;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end;
      z-index: 2; }
    .banner-slider .item__inner {
      position: relative;
      padding-right: 5.85714em; }
    .banner-slider .item.animated {
      z-index: 10; }
      .banner-slider .item.animated .item__label, .banner-slider .item.animated .item__desc, .banner-slider .item.animated .item__btn {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1; }
      .banner-slider .item.animated .item__line {
        width: 2.5em;
        opacity: 1; }
      .banner-slider .item.animated .item__number {
        color: #F3B612; }
  .banner-slider__control {
    position: absolute;
    z-index: 10;
    bottom: 4.21429em;
    left: 0;
    width: 100%; }
  .banner-slider__paging {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    position: absolute;
    bottom: 6.57143em;
    right: 0;
    width: 100%; }
  .banner-slider .slick-arrow {
    color: #a5a19f; }
  .banner-slider .paging {
    width: 20px;
    height: 126px; }
    .banner-slider .paging__item {
      position: relative;
      height: 44px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      font-weight: bold;
      font-size: 1.07143em;
      line-height: 130%;
      color: #a5a19f;
      cursor: pointer;
      -webkit-transition: color 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;
      text-align: center; }
      .banner-slider .paging__item.slick-current {
        color: #F3B612; }
    .banner-slider .paging .slick-arrow {
      width: 100%;
      text-align: center; }
    .banner-slider .paging .slick-prev {
      top: -17px;
      -webkit-transform: none;
              transform: none; }
    .banner-slider .paging .slick-next {
      top: auto;
      -webkit-transform: none;
              transform: none;
      bottom: -28px; }
  .banner-slider--small .main-title {
    color: #ffffff;
    margin-bottom: 0.5em; }
  .banner-slider--small .item {
    height: 542px; }
    .banner-slider--small .item__caption {
      position: absolute;
      top: 0;
      left: 0;
      bottom: auto;
      right: auto;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end;
      text-align: left;
      padding-bottom: 6.42857em; }
    .banner-slider--small .item:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(33, 33, 33, 0.4);
      position: absolute;
      top: 0;
      left: 0; }
    .banner-slider--small .item__desc {
      max-width: 366px; }
    .banner-slider--small .item__label, .banner-slider--small .item__desc, .banner-slider--small .item__btn {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1; }
    .banner-slider--small .item__line {
      width: 2.5em;
      opacity: 1; }
    .banner-slider--small .item__number {
      color: #F3B612; }
  .banner-slider--small .banner-slider__paging {
    bottom: 50%;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
  .banner-slider--small .slick-arrow {
    color: #C2C2C2; }
  .banner-slider--small .paging__item {
    color: #C2C2C2; }
  @media (max-width: 991px) {
    .banner-slider--main .item__caption {
      bottom: 58px; } }
  @media (max-width: 768px) {
    .banner-slider--main {
      background-color: #F2EFEC;
      padding-bottom: 10px; }
      .banner-slider--main .item {
        height: auto; }
        .banner-slider--main .item::before {
          display: none; }
        .banner-slider--main .item img {
          height: auto; }
        .banner-slider--main .item__caption {
          position: relative;
          bottom: auto;
          left: auto;
          margin-top: 21px; }
        .banner-slider--main .item__inner {
          padding-right: 0; }
        .banner-slider--main .item__line {
          display: none; }
      .banner-slider--main .banner-slider__paging {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
        top: auto;
        padding: 0 15px; }
      .banner-slider--main .paging {
        width: 125px;
        height: auto; }
        .banner-slider--main .paging__item {
          -webkit-box-pack: center;
                  justify-content: center; }
        .banner-slider--main .paging .slick-arrow {
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%);
          width: 15px; }
          .banner-slider--main .paging .slick-arrow svg {
            width: 10px;
            height: auto; }
        .banner-slider--main .paging .slick-prev {
          top: 50%;
          left: -15px; }
          .banner-slider--main .paging .slick-prev svg {
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg); }
        .banner-slider--main .paging .slick-next {
          top: 50%;
          bottom: auto;
          right: -15px; }
          .banner-slider--main .paging .slick-next svg {
            -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg); }
    .banner-slider--small .item {
      height: 400px; } }

.trends .section-info .main-label {
  margin-bottom: 2.75em; }

.trends .section-info .main-title {
  margin-bottom: 7px;
  position: relative; }

.trends .section-info .main-label::before {
  left: -93px;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out; }

.trends .section-info__desc {
  margin-bottom: 2.1875em; }

@media (max-width: 991px) {
  .trends .section-info__caption {
    margin-top: 30px; }
    .trends .section-info__caption .main-label {
      padding-left: 45px; }
      .trends .section-info__caption .main-label::before {
        left: 0;
        width: 30px; }
  .trends .section-info__img {
    height: 320px;
    -o-object-fit: cover;
       object-fit: cover; }
  .trends .section-info__shadow {
    top: -10px;
    right: -10px; } }

@media (max-width: 768px) {
  .trends .section-info {
    padding: 50px 0; }
    .trends .section-info__img {
      height: auto; }
    .trends .section-info__caption {
      margin-top: 30px; } }

.brands {
  background-color: #1D1D1D;
  padding-top: 5.78571em; }
  .brands__inner {
    max-width: 1017px;
    margin: 0 auto; }
  .brands .main-label {
    margin-bottom: 2.08333em; }
  .brands .main-title {
    color: #ffffff;
    margin-bottom: 1.19643em; }
  .brands .brand {
    padding-bottom: 8.21429em; }
    .brands .brand .row-custom {
      margin: 0 -30px; }
    .brands .brand .col-custom {
      padding: 0 30px; }
    .brands .brand:nth-child(even) .col-custom--img {
      -webkit-box-ordinal-group: 0;
              order: -1; }
    .brands .brand:nth-child(even) .brand__number {
      text-align: right; }
    .brands .brand:nth-child(even) .brand__caption {
      -webkit-box-ordinal-group: 0;
              order: -1;
      text-align: right; }
    .brands .brand:nth-child(even) .brand__link:after {
      left: auto;
      right: 0; }
    .brands .brand:nth-child(even) .brand__shadow {
      right: auto;
      left: -15px; }
    .brands .brand:nth-child(even) .brand__img {
      position: relative; }
    .brands .brand__info {
      display: -webkit-box;
      display: flex; }
    .brands .brand__number {
      width: 60px;
      font-family: "DINCondensed-Bold";
      color: #333333;
      font-size: 2.85714em;
      line-height: 1em;
      letter-spacing: 0.06em; }
    .brands .brand__caption {
      width: calc(100% - 60px); }
    .brands .brand__name {
      font-weight: bold;
      font-size: 5.71429em;
      line-height: 1em;
      font-family: "DINCondensed-Bold";
      color: #ffffff; }
    .brands .brand__desc {
      font-size: 1.14286em;
      line-height: 1.5em;
      letter-spacing: 0.02em;
      margin-bottom: 1.875em;
      position: relative; }
    .brands .brand__link {
      color: #F3B612;
      line-height: 1.28571em;
      letter-spacing: 0.12em;
      font-weight: bold;
      display: inline-block;
      position: relative;
      padding-bottom: 1em; }
      .brands .brand__link:hover:after {
        width: 75px; }
      .brands .brand__link:after {
        content: '';
        width: 0;
        height: 1px;
        background-color: #F3B612;
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
    .brands .brand__img {
      z-index: 5;
      width: 100%;
      position: relative; }
    .brands .brand__shape {
      position: relative; }
    .brands .brand__shadow {
      position: absolute;
      width: 30.92857em;
      height: 30.92857em;
      top: -15px;
      right: -15px;
      background-color: #F3B612; }
  @media (max-width: 991px) {
    .brands .brand__shadow {
      width: 95.58499%;
      height: 83.26923%;
      top: -10px;
      right: -10px; }
    .brands .brand__img {
      position: relative; }
    .brands .brand .col-custom--img {
      -webkit-box-ordinal-group: 0;
              order: -1;
      margin-bottom: 30px; }
    .brands .brand .col-custom--text {
      margin-top: 30px; }
    .brands .brand__number {
      position: relative; }
      .brands .brand__number__inner {
        -webkit-transform: scale(1);
                transform: scale(1); } }
  @media (max-width: 768px) {
    .brands .brand {
      padding-bottom: 50px; }
      .brands .brand .col-custom--img {
        -webkit-box-ordinal-group: 0;
                order: -1;
        margin-bottom: 30px; }
      .brands .brand .col-custom--text {
        margin-top: 30px; } }

.brand-logos {
  padding-top: 6.92857em;
  padding-bottom: 6.64286em; }
  .brand-logos .main-label {
    margin-bottom: 2em; }
  .brand-logos .slider-logos {
    max-width: 1200px;
    margin: 56px auto 0 auto;
    padding: 0 45px; }
    .brand-logos .slider-logos .logo {
      padding: 0 25px;
      height: 120px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      -webkit-box-pack: center;
              justify-content: center; }
  .brand-logos .slick-arrow {
    color: #000000; }
    .brand-logos .slick-arrow:hover {
      color: #F3B612; }
  @media (max-width: 768px) {
    .brand-logos {
      padding: 50px 15px; } }

.our-team .section-info {
  padding-top: 10.14286em;
  padding-bottom: 9.35714em; }
  .our-team .section-info .main-label {
    margin-bottom: 2em; }
    .our-team .section-info .main-label::before {
      left: -93px; }
  .our-team .section-info .main-title {
    margin-bottom: 0.26786em; }
  .our-team .section-info__desc {
    margin-bottom: 1.875em; }
  .our-team .section-info__shape {
    position: relative; }
    .our-team .section-info__shape:after {
      content: '';
      position: absolute;
      display: block;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.35); }
  .our-team .section-info__shadow {
    top: -15px;
    right: -15px; }

@media (max-width: 991px) {
  .our-team .section-info__img {
    height: 320px;
    -o-object-fit: cover;
       object-fit: cover;
    left: 0; }
  .our-team .section-info__shadow {
    top: -10px;
    right: -10px; }
  .our-team .section-info__caption {
    margin-top: 2.14286em; } }

@media (max-width: 768px) {
  .our-team .section-info {
    padding: 50px 0; }
    .our-team .section-info__caption {
      margin-top: 30px; } }

.about_banner {
  font-size: 14px;
  position: relative; }
  .about_banner__img {
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
  .about_banner__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 12;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .about_banner__content::before {
      content: '';
      width: 100%;
      height: 100%;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0; }
    .about_banner__content .container {
      z-index: 1;
      position: relative; }
  .about_banner__title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.07143em;
    line-height: 19px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    color: #ffffff;
    padding-left: 10em;
    position: relative;
    margin-bottom: 2.86667em; }
    .about_banner__title::after {
      content: '';
      width: 8em;
      position: absolute;
      left: -2em;
      top: 50%;
      height: 2px;
      margin-top: -0.5px;
      background: rgba(255, 255, 255, 0.2); }
  .about_banner__des {
    padding-left: 150px;
    font-family: "DINCondensed-Bold";
    font-weight: bold;
    font-size: 70px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff; }
  @media (max-width: 768px) {
    .about_banner__img {
      height: 544px; }
    .about_banner__title {
      font-size: 13px;
      line-height: 16px;
      padding-left: 70px;
      margin-bottom: 30px; }
      .about_banner__title::after {
        width: 44px;
        left: 0; }
    .about_banner__des {
      padding-left: 0;
      font-size: 50px; }
    .about_banner__content {
      height: 100%;
      top: auto;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      bottom: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: end;
              align-items: flex-end;
      padding-bottom: 40px; }
      .about_banner__content::before {
        content: '';
        width: 100%;
        height: 100%;
        background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        opacity: 0.7;
        position: absolute;
        top: 0;
        left: 0; }
      .about_banner__content .container {
        z-index: 1;
        position: relative; } }

.about_mission {
  font-size: 14px;
  height: 693px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 160px;
  margin-bottom: 150px;
  background-attachment: fixed;
  position: relative; }
  .about_mission__img {
    position: relative;
    display: inline-block; }
    .about_mission__img img {
      margin-bottom: -76px;
      position: relative;
      z-index: 12;
      max-height: 612px; }
    .about_mission__img::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: -15px;
      right: -15px;
      width: 100%;
      height: 100%;
      max-width: 329px;
      max-height: 73.52941%;
      background: #F3B612; }
  .about_mission__info {
    padding-left: 175px; }
  .about_mission__sub {
    margin-bottom: 25px; }
    .about_mission__sub::before {
      width: 120px;
      left: -150px; }
  .about_mission__title {
    font-family: "DINCondensed-Bold";
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 38px; }
  .about_mission__des {
    font-size: 1.07143em;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: #bbbbbb;
    max-width: 27.66667em; }
    .about_mission__des p {
      margin-bottom: 18px; }
  .about_mission__top {
    height: 50px;
    width: 1px;
    position: absolute;
    left: 50%;
    top: 0;
    background: #ffffff; }
  @media (max-width: 991px) {
    .about_mission {
      height: auto;
      padding: 100px 0;
      margin-bottom: 0; }
      .about_mission__info {
        padding-left: 0; }
      .about_mission__img img {
        margin-bottom: 0; } }
  @media (max-width: 768px) {
    .about_mission {
      padding-top: 50px;
      padding-bottom: 0;
      margin-bottom: 50px; }
      .about_mission .col-img {
        text-align: center; }
      .about_mission .main-label:before {
        width: 30px;
        left: 0; }
      .about_mission__des {
        max-width: 100%; }
      .about_mission__img {
        margin-top: 30px; }
        .about_mission__img img {
          margin-bottom: -50px; } }

.about_wwd {
  padding-bottom: 10.71429em;
  position: relative; }
  .about_wwd__info {
    background: #ffffff;
    height: 371px;
    margin-left: -30px;
    position: relative; }
  .about_wwd__brands {
    position: absolute;
    left: 90px;
    bottom: -45px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end; }
    .about_wwd__brands:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: -13px;
      left: 0;
      z-index: 1; }
  .about_wwd__brand {
    margin-right: 30px;
    font-size: 18px;
    line-height: 1.8;
    letter-spacing: 0.02em;
    display: inline-block;
    color: #111111;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 2; }
    .about_wwd__brand:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #cccccc;
      position: absolute;
      bottom: -17.5px;
      left: 50%;
      margin-left: -5px;
      border-radius: 50%; }
    .about_wwd__brand.active {
      font-weight: bold;
      font-size: 25px;
      color: #212121; }
      .about_wwd__brand.active:after {
        background-color: #F3B612; }
  .about_wwd__sub {
    margin-bottom: 25px;
    top: -70px;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
    .about_wwd__sub::before {
      width: 120px;
      left: -150px; }
  .about_wwd__title {
    margin-bottom: 15px;
    top: -50px;
    opacity: 0;
    -webkit-transition-delay: 0.2;
            transition-delay: 0.2;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: relative; }
  .about_wwd__des {
    font-size: 1em;
    line-height: 1.5;
    letter-spacing: 0.02em;
    color: #434343;
    text-align: justify;
    position: relative;
    bottom: -50px;
    opacity: 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out; }
  .about_wwd__line {
    height: 57px;
    width: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    background: #131313; }
  .about_wwd__img {
    height: 606px;
    position: relative; }
    .about_wwd__img img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 0;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out; }
      .about_wwd__img img.active {
        opacity: 1; }
  .about_wwd__caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 6.42857em;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center; }
    .about_wwd__caption.active .about_wwd__sub {
      opacity: 1;
      top: 0; }
    .about_wwd__caption.active .about_wwd__title {
      opacity: 1;
      top: 0; }
    .about_wwd__caption.active .about_wwd__des {
      opacity: 1;
      bottom: 0; }
  @media (max-width: 991px) {
    .about_wwd__caption {
      padding: 0 30px; } }
  @media (max-width: 768px) {
    .about_wwd {
      padding: 0 0 120px 0; }
      .about_wwd .main-label {
        padding-left: 45px; }
        .about_wwd .main-label::before {
          width: 30px;
          left: 0; }
      .about_wwd__brands {
        padding-left: 0;
        left: 0;
        bottom: -60px; }
      .about_wwd__info {
        height: 250px;
        padding: 0;
        margin: 0;
        margin-top: 30px; }
      .about_wwd__line {
        height: 30px; }
      .about_wwd__img {
        width: 100%;
        height: 320px; }
        .about_wwd__img img {
          width: 100%; }
      .about_wwd__caption {
        padding: 0; } }

.about_brands {
  font-size: 14px;
  background: #131313;
  padding-top: 90px;
  padding-bottom: 102px;
  position: relative; }
  .about_brands__sub {
    margin-bottom: 25px; }
    .about_brands__sub .main-label__text {
      background: #131313; }
  .about_brands__title {
    margin-bottom: 0.85714em;
    text-align: center;
    color: #ffffff;
    font-family: "DINCondensed-Bold";
    font-weight: bold;
    font-size: 55px; }
  .about_brands__list {
    border-top: 0.75px solid #434343;
    border-left: 0.75px solid #434343; }
  .about_brands__item {
    border-right: 0.75px solid #434343;
    border-bottom: 0.75px solid #434343;
    padding: 1px; }
    .about_brands__item .flip_card__front {
      padding: 30px; }
  .about_brands__top {
    height: 50px;
    width: 1px;
    position: absolute;
    left: 50%;
    top: 0;
    background: #ffffff; }
  .about_brands__bottom {
    height: 50px;
    width: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    background: #d1d1d1; }
  @media (max-width: 768px) {
    .about_brands__top, .about_brands__bottom {
      height: 30px; } }
  @media (max-width: 440px) {
    .about_brands__list > div {
      -webkit-box-flex: 0;
              flex: 0 0 100%;
      max-width: 100%; }
    .about_brands__item .flip_card {
      height: 350px; } }
  @media (max-width: 360px) {
    .about_brands__item .flip_card {
      height: 250px; } }

.about_career {
  position: relative;
  padding-top: 46px; }
  .about_career__sub {
    margin-bottom: 25px;
    margin-top: 20px;
    z-index: 12; }
  .about_career__title {
    margin-bottom: 24px; }
  .about_career .section-info__shadow {
    height: calc(100% + 30px);
    max-width: 433px;
    left: -15px;
    bottom: -15px;
    right: auto; }
  .about_career__img {
    margin-left: 30px; }
  .about_career__info {
    padding-left: 102px; }
    .about_career__info .main-label::before {
      z-index: 12;
      width: 94px;
      left: -120px; }
  .about_career__des {
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 0.02em;
    color: #6c6c6c;
    max-width: 410px; }
  .about_career__top {
    height: 50px;
    width: 1px;
    position: absolute;
    left: 50%;
    top: 0;
    background: #212121; }
  @media (max-width: 991px) {
    .about_career .section-info {
      padding: 50px 0; }
    .about_career__info {
      padding-left: 30px; }
      .about_career__info .main-label {
        padding-left: 45px; }
        .about_career__info .main-label::before {
          width: 30px;
          left: 0; } }
  @media (max-width: 768px) {
    .about_career {
      padding-top: 0; }
      .about_career__img {
        margin-left: 0; }
      .about_career__info {
        padding-left: 0;
        margin-top: 50px; }
      .about_career__des {
        max-width: unset; }
      .about_career__top {
        height: 30px; } }

.about_team__sub {
  margin-bottom: 25px;
  z-index: 12; }

.about_team__title {
  margin-bottom: 24px; }

.about_team__img {
  display: inline-block;
  left: -50px; }
  .about_team__img img {
    max-width: 592px; }
  .about_team__img .section-info__shadow {
    height: 63.86431%;
    max-width: 58.78378%;
    bottom: -15px;
    top: auto; }

.about_team__info {
  padding-left: 80px;
  padding-right: 90px;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #ffffff;
  position: relative;
  z-index: 15; }
  .about_team__info .main-label::before {
    z-index: 12;
    width: 94px;
    left: -120px; }

.about_team__des {
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.02em;
  color: #6c6c6c;
  max-width: 380px; }

@media (max-width: 991px) {
  .about_team .section-info {
    padding: 50px 0 75px 0; }
  .about_team__info {
    padding: 30px; }
    .about_team__info .main-label {
      padding-left: 45px; }
      .about_team__info .main-label::before {
        width: 30px;
        left: 0; } }

@media (max-width: 768px) {
  .about_team .col-img {
    -webkit-box-ordinal-group: 0;
            order: -1; }
  .about_team .section-info {
    padding: 0 0 75px 0; }
  .about_team__info {
    padding: 0;
    margin-top: 50px; }
  .about_team__img {
    left: 0; } }

@media (max-width: 991px) {
  .about_number .numbers {
    padding: 50px 0 0 0; } }

@media (max-width: 768px) {
  .about_number {
    margin-top: 50px; } }

.connect-with-us {
  padding-top: 6.42857em; }
  .connect-with-us .main-title-page {
    margin-bottom: 0.2em; }
  .connect-with-us .pad-bot-15 {
    padding-bottom: 15px; }
  .connect-with-us .section-connect {
    margin-bottom: 5.71429em; }
    .connect-with-us .section-connect--partners {
      margin-bottom: 4em; }
    .connect-with-us .section-connect .main-label {
      margin-bottom: 2.08333em; }
  .connect-with-us .addresses {
    margin-top: 3.07143em; }
    .connect-with-us .addresses .item__caption {
      padding: 1.21429em 1.92857em 0 1.92857em;
      background-color: #ffffff; }
    .connect-with-us .addresses .item__title {
      font-weight: 600;
      font-size: 1.57143em;
      line-height: 1.59091em;
      color: #212121;
      font-weight: normal;
      border-bottom: 1px solid rgba(243, 182, 18, 0.4);
      margin-bottom: 0.68182em;
      padding-bottom: 0.40909em; }
    .connect-with-us .addresses .item__desc, .connect-with-us .addresses .item__phone {
      font-size: 1.21429em;
      line-height: 1.23529em;
      color: #212121;
      margin-bottom: 0; }
    .connect-with-us .addresses .item__desc {
      margin-bottom: 3px; }
    .connect-with-us .addresses .item__phone {
      margin-bottom: 1.17647em; }
    .connect-with-us .addresses .item__link {
      color: #6C6C6C;
      text-decoration: underline;
      font-size: 1.28571em;
      line-height: 1.27778em; }
      .connect-with-us .addresses .item__link:hover {
        color: #F3B612; }
    .connect-with-us .addresses .item--inside {
      position: relative; }
      .connect-with-us .addresses .item--inside .item__caption {
        width: 50%;
        position: absolute;
        bottom: 0;
        left: 0; }
    @media (max-width: 768px) {
      .connect-with-us .addresses .item {
        margin-top: 50px; }
        .connect-with-us .addresses .item--inside {
          margin-top: 0; }
          .connect-with-us .addresses .item--inside .item__caption {
            width: 100%;
            position: static;
            top: auto;
            left: auto; } }
  .connect-with-us .partners {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-top: -2px; }
    .connect-with-us .partners .item {
      border-left: 1px solid #E7E7E7; }
      .connect-with-us .partners .item:first-child {
        border-left: none; }
      .connect-with-us .partners .item--small {
        width: 23.40702%; }
      .connect-with-us .partners .item--large {
        width: 23.40702%;
        text-align: center; }
      .connect-with-us .partners .item--last {
        text-align: right; }
      .connect-with-us .partners .item__inner {
        display: inline-block;
        text-align: center;
        padding-left: 10px; }
      .connect-with-us .partners .item__icon {
        position: relative;
        top: -2px; }
      .connect-with-us .partners .item__phone {
        width: 100%;
        font-size: 1.14286em;
        line-height: 1em;
        letter-spacing: 0.03em;
        color: #212121;
        margin-top: 1.375em; }
      .connect-with-us .partners .item__label {
        display: -webkit-inline-box;
        display: inline-flex;
        -webkit-box-align: center;
                align-items: center;
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #E6E6E6; }
      .connect-with-us .partners .item__logo {
        display: inline-block;
        height: 57px; }
        .connect-with-us .partners .item__logo img {
          max-width: 158px;
          height: 57px;
          -o-object-fit: contain;
             object-fit: contain; }
    @media (max-width: 991px) {
      .connect-with-us .partners {
        margin-top: 30px; } }
    @media (max-width: 768px) {
      .connect-with-us .partners {
        -webkit-box-align: start;
                align-items: flex-start;
        margin-top: 0; }
        .connect-with-us .partners .item {
          width: 50%;
          border: none;
          text-align: center;
          padding-top: 30px; } }
    @media (max-width: 400px) {
      .connect-with-us .partners .item {
        width: 100%; } }
  .connect-with-us .social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    margin-top: -13px; }
    .connect-with-us .social__desc {
      font-size: 1.14286em;
      line-height: 1.625em;
      width: calc(100% - 157px); }
    .connect-with-us .social .listlinks {
      width: 157px;
      margin-bottom: 0; }
      .connect-with-us .social .listlinks .item {
        display: inline-block;
        margin-left: 22px; }
        .connect-with-us .social .listlinks .item:first-child {
          margin-left: 0; }
        .connect-with-us .social .listlinks .item__link {
          color: #212121; }
    @media (max-width: 991px) {
      .connect-with-us .social {
        margin-top: 30px; } }
    @media (max-width: 768px) {
      .connect-with-us .social {
        flex-wrap: wrap; }
        .connect-with-us .social__desc {
          width: 100%;
          margin-top: 30px; } }

.furla-about {
  padding: 4.28571em 0 10.21429em 0; }
  .furla-about__row {
    position: relative; }
  .furla-about__col--image {
    width: 76.41026%; }
    .furla-about__col--image img {
      width: 100%; }
  .furla-about__col--text {
    width: 46.15385%;
    position: absolute;
    bottom: -5.78571em;
    right: 0;
    z-index: 5;
    background-color: #ffffff;
    padding: 5.07143em 2.85714em 4em 6.42857em; }
  .furla-about__desc {
    line-height: 1.57143em;
    margin-bottom: 0; }
  .furla-about .main-label {
    margin-bottom: 2.16667em; }
    .furla-about .main-label::before {
      width: 7.75em;
      left: -115px; }
  .furla-about .main-title {
    margin-bottom: 0.44643em; }
  @media (max-width: 768px) {
    .furla-about {
      padding: 50px 0; }
      .furla-about .main-label {
        padding-left: 45px; }
        .furla-about .main-label::before {
          width: 30px;
          left: 0; }
      .furla-about__col--image, .furla-about__col--text {
        width: 100%; }
      .furla-about__col--text {
        position: relative;
        bottom: auto;
        right: auto;
        padding: 0;
        margin-top: 50px; } }

.furla-history {
  color: #ffffff;
  padding: 8.71429em 0 0 0;
  position: relative; }
  .furla-history::before {
    content: '';
    width: 100%;
    height: calc(100% - 74px);
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1D1D1D; }
  .furla-history .container {
    position: relative;
    z-index: 5; }
  .furla-history .main-title {
    color: #ffffff;
    margin-bottom: 0.35714em; }
  .furla-history .main-label {
    margin-bottom: 2.08333em; }
    @media (max-width: 768px) {
      .furla-history .main-label {
        margin-bottom: 2.91667em; } }
  .furla-history__desc {
    max-width: 400px; }
  .furla-history__image {
    display: inline-block;
    position: relative; }
    .furla-history__image img {
      position: relative;
      z-index: 5; }
    .furla-history__image:after {
      content: '';
      width: 73.66483%;
      height: 83.0033%;
      position: absolute;
      top: -15px;
      right: -15px;
      background-color: #F3B612; }
  @media (max-width: 991px) {
    .furla-history__text {
      padding-top: 0;
      padding-bottom: 6.42857em; } }
  @media (max-width: 768px) {
    .furla-history {
      padding: 3.57143em 0 0 0; }
      .furla-history::before {
        height: 100%; }
      .furla-history__thumb {
        -webkit-box-ordinal-group: 0;
                order: -1; }
      .furla-history__desc p {
        margin-top: 0.71429em;
        margin-bottom: 0; }
        .furla-history__desc p:first-child {
          margin-top: 0; }
      .furla-history__text {
        margin-top: 3.57143em;
        padding-bottom: 2.85714em; } }

.furla-store {
  padding: 6.28571em 0 0 0; }
  .furla-store .list-store {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.85714em; }
    .furla-store .list-store .item__name {
      cursor: pointer; }
  .furla-store .main-label {
    margin-bottom: 2.08333em; }
  .furla-store .main-title {
    margin-bottom: 0.85714em; }
  .furla-store .image-gosumo {
    height: 410px;
    -o-object-fit: cover;
       object-fit: cover; }
  .furla-store .item {
    padding: 0 0.85714em;
    color: #212121;
    margin-bottom: 5.28571em;
    -webkit-box-flex: 0;
            flex: 0 33.33333%; }
    .furla-store .item__image {
      margin-bottom: 1.28571em;
      width: 100%;
      height: 410px;
      -o-object-fit: cover;
         object-fit: cover; }
    .furla-store .item__2per-row {
      -webkit-box-flex: 0;
              flex: 0 50%; }
    .furla-store .item__3per-row {
      -webkit-box-flex: 0;
              flex: 0 33.33333%; }
      .furla-store .item__3per-row .item__image {
        height: 300px; }
    .furla-store .item__name {
      margin-bottom: 7px;
      font-weight: 600;
      font-size: 1.42857em;
      line-height: 1.6em; }
    .furla-store .item__address {
      margin-bottom: 6px;
      font-size: 1.14286em;
      line-height: 1.25em; }
    .furla-store .item__phone {
      margin-bottom: 0;
      font-size: 1.14286em;
      line-height: 1.25em; }
      .furla-store .item__phone a {
        color: #212121; }
  @media (max-width: 768px) {
    .furla-store {
      padding: 3.57143em 0 0 0; }
      .furla-store .item {
        -webkit-box-flex: 0;
                flex: 0 100%;
        margin-bottom: 3.57143em; } }

.store-location--furla .main-btn {
  background: transparent; }
  .store-location--furla .main-btn:hover {
    color: #212121; }

.geox-info {
  padding: 4.28571em 0; }
  .geox-info__row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
            align-items: center; }
  .geox-info__col {
    width: 40.66667%; }
    .geox-info__col:first-child {
      width: 59.33333%; }
    .geox-info__col--padding {
      padding-left: 5.85714em;
      padding-right: 1.42857em; }
  .geox-info__desc {
    font-size: 1.14286em;
    line-height: 1.6875em;
    letter-spacing: 0.02em;
    color: #4B4B4B;
    margin-bottom: 0;
    margin-top: 1.875em; }
  .geox-info__slider {
    position: relative;
    margin-top: 2.85714em; }
  .geox-info .geox-slider {
    background-color: #f5f7fb;
    padding-bottom: 3.92857em;
    position: relative; }
  .geox-info .geox-paging {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    height: 2.75em;
    position: absolute;
    bottom: 0;
    left: 200px;
    z-index: 10;
    font-family: "Oswald";
    font-size: 1.42857em;
    font-weight: 500;
    line-height: 1em; }
    .geox-info .geox-paging .number {
      color: #212121;
      opacity: 0.4;
      padding: 0 8px;
      display: inline-block;
      border-left: 1px solid rgba(33, 33, 33, 0.4); }
      .geox-info .geox-paging .number:first-child {
        opacity: 1;
        border-left: none; }
  .geox-info .slick-arrow {
    font-size: unset;
    -webkit-transform: none;
            transform: none;
    top: auto;
    bottom: 0;
    width: 3.92857em;
    height: 3.92857em;
    background-color: #4272c8; }
  .geox-info .slick-prev {
    left: 5.85714em; }
  .geox-info .slick-next {
    right: auto;
    left: 9.85714em; }
  .geox-info .item {
    padding-top: 4.35714em;
    padding-left: 5.85714em;
    min-height: 345px; }
    .geox-info .item__title {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
              justify-content: space-between;
      padding-right: 5.85714em;
      border-bottom: 1px solid #d5dae4;
      padding-left: 8px;
      padding-bottom: 1.21429em; }
    .geox-info .item__label {
      font-weight: bold;
      font-size: 3.21429em;
      line-height: 1em;
      color: #4272c8;
      padding-right: 0.66667em; }
    .geox-info .item__number {
      font-weight: 500;
      font-size: 2.5em;
      line-height: 1em;
      color: #02274b;
      opacity: 0.2;
      font-family: "Oswald"; }
    .geox-info .item__desc {
      font-size: 1.14286em;
      line-height: 1.6875em;
      letter-spacing: 0.02em;
      color: #212121;
      padding-right: 5.125em;
      margin-bottom: 0;
      padding-top: 1.5625em; }
  .geox-info--even .geox-info {
    width: 59.33333%; }
    .geox-info--even .geox-info:first-child {
      width: 40.66667%; }
  .geox-info--history .geox-info__slider .geox-slider {
    background: #ffffff; }
  .geox-info--history .geox-info__slider .item {
    padding-top: 0;
    padding-left: 0; }
  .geox-info--history .geox-info__slider .slick-prev {
    left: 0; }
  .geox-info--history .geox-info__slider .slick-next {
    right: auto;
    left: 4em; }
  .geox-info--history .geox-info__slider .geox-paging {
    left: 118px; }
  @media (max-width: 1200px) {
    .geox-info--history .geox-info__col--image {
      padding-left: 0; } }
  @media (max-width: 991px) {
    .geox-info {
      padding: 0 0 50px 0; }
      .geox-info--history {
        padding-top: 50px; }
      .geox-info__row {
        flex-wrap: wrap; }
      .geox-info__col {
        width: 100%; }
        .geox-info__col:first-child {
          width: 100%; }
        .geox-info__col--padding {
          padding-left: 0; }
        .geox-info__col--image {
          -webkit-box-ordinal-group: 0;
                  order: -1;
          margin-bottom: 2.14286em; }
          .geox-info__col--image img {
            width: 100%; }
      .geox-info .item {
        padding-left: 0;
        padding-top: 30px; }
        .geox-info .item__title {
          padding-left: 15px; }
        .geox-info .item__desc {
          padding: 15px; }
      .geox-info .slick-prev {
        left: 15px; }
      .geox-info .slick-next {
        left: 63px; }
      .geox-info .geox-paging {
        left: 118px; } }

.geox-feature {
  position: relative;
  padding: 50px 0 88px 0; }
  .geox-feature__paging {
    max-width: 600px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    position: relative; }
  .geox-feature__slider .item {
    line-height: 0;
    width: 1200px;
    overflow: hidden; }
    .geox-feature__slider .item.animated img {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1; }
    .geox-feature__slider .item img {
      height: 570px;
      -o-object-fit: cover;
         object-fit: cover;
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      width: 100%;
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      opacity: .5; }
  .geox-feature__slider .slick-list {
    padding: 0 0 0 calc(100% - 1200px); }
  .geox-feature__thumb-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    top: 0;
    left: 0; }
    .geox-feature__thumb-slider .number {
      position: absolute;
      bottom: 120px;
      right: 0;
      width: 55px;
      text-align: center;
      font-size: 1.42857em;
      line-height: 1em;
      font-family: "Oswald";
      padding: 0 0.75em;
      color: #333333; }
      .geox-feature__thumb-slider .number__item {
        padding: 8px 0; }
        .geox-feature__thumb-slider .number__item:first-child {
          font-weight: 500;
          color: #02274B;
          border-bottom: 1px solid #000000; }
    .geox-feature__thumb-slider .item {
      background-color: #ffffff;
      height: 430px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
              align-items: center;
      padding: 0 4.28571em; }
      .geox-feature__thumb-slider .item__title {
        font-size: 3.21429em;
        line-height: 1em;
        color: #02274B;
        font-weight: bold; }
      .geox-feature__thumb-slider .item__desc {
        font-size: 1.14286em;
        line-height: 1.625em;
        font-family: "Cabin"; }
    .geox-feature__thumb-slider .slick-arrow {
      width: 3.92857em;
      height: 3.92857em;
      -webkit-transform: none;
              transform: none;
      left: auto;
      right: 0;
      background-color: #4271C8;
      font-size: unset;
      z-index: 5;
      top: auto; }
    .geox-feature__thumb-slider .slick-prev {
      left: auto;
      bottom: 56px; }
      .geox-feature__thumb-slider .slick-prev svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
    .geox-feature__thumb-slider .slick-next {
      bottom: 0; }
  @media (max-width: 991px) {
    .geox-feature {
      height: auto; }
      .geox-feature__paging {
        box-shadow: none; }
      .geox-feature__thumb-slider {
        position: static;
        top: auto;
        left: auto;
        padding-bottom: 50px; }
        .geox-feature__thumb-slider .item {
          padding: 50px 0;
          height: auto; }
        .geox-feature__thumb-slider .slick-prev {
          left: 0;
          right: auto;
          bottom: 0; }
        .geox-feature__thumb-slider .slick-next {
          left: 48px;
          bottom: 0; }
        .geox-feature__thumb-slider .number {
          height: 2.75em;
          width: auto;
          padding: 0;
          display: -webkit-box;
          display: flex;
          bottom: 0;
          left: 103px;
          -webkit-box-align: center;
                  align-items: center; }
          .geox-feature__thumb-slider .number__item {
            padding: 0 8px;
            border-bottom: none; }
            .geox-feature__thumb-slider .number__item:first-child {
              border-bottom: none;
              border-right: 1px solid #02274B; } }
  @media only screen and (max-width: 1366px) {
    .geox-feature__slider .item {
      width: 1170px; }
    .geox-feature__slider .slick-list {
      padding: 0 0 0 calc(100% - 1170px); } }
  @media only screen and (max-width: 1366px) {
    .geox-feature__slider .item {
      width: 1024px; }
    .geox-feature__slider .slick-list {
      padding: 0 0 0 calc(100% - 1024px); } }
  @media only screen and (max-width: 1200px) {
    .geox-feature__slider .item {
      width: 960px; }
    .geox-feature__slider .slick-list {
      padding: 0 0 0 calc(100% - 960px); } }
  @media only screen and (max-width: 1024px) {
    .geox-feature__slider .item {
      width: 720px; }
    .geox-feature__slider .slick-list {
      padding: 0 0 0 calc(100% - 720px); } }
  @media (max-width: 991px) {
    .geox-feature {
      padding: 0; }
      .geox-feature__slider .item {
        width: 100%; }
        .geox-feature__slider .item img {
          height: auto; }
      .geox-feature__slider .slick-list {
        padding: 0; } }

.shooz-section:nth-child(odd) {
  background-color: #f5f5f5; }

.shooz-info__title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
          align-items: flex-start;
  margin-bottom: 0.66667em; }

.shooz-info__icon {
  max-width: 91px;
  position: relative;
  top: -16px; }

.shooz-info__caption {
  padding: 0 7.14286em;
  margin: 0 auto; }

.shooz-info__label {
  color: #fbe106;
  white-space: nowrap; }

.shooz-info__image {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover; }

.shooz-info__desc p {
  margin-top: 1.42857em;
  margin-bottom: 0; }
  .shooz-info__desc p:first-child {
    margin-top: 0; }

.shooz-info .main-title {
  line-height: 1.3em; }

@media (max-width: 991px) {
  .shooz-info__caption {
    padding: 0 3.57143em; } }

@media (max-width: 768px) {
  .shooz-info__caption {
    padding: 3.57143em 1.07143em; }
  .shooz-info__image {
    height: auto; } }

.shooz-brands {
  padding: 5.5em 2.64286em 6.42857em 2.64286em; }
  .shooz-brands .main-label {
    margin-bottom: 0.91667em; }
  .shooz-brands .main-title {
    margin-bottom: 1.76667em; }
  .shooz-brands .item {
    height: 478px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 0 2px; }
    .shooz-brands .item__link {
      display: -webkit-inline-box;
      display: inline-flex;
      height: 237px;
      -webkit-box-align: center;
              align-items: center;
      background-color: #ffffff;
      -webkit-box-pack: center;
              justify-content: center; }
      .shooz-brands .item__link img {
        max-width: 100%; }
  .shooz-brands .slick-prev {
    left: -37px; }
  .shooz-brands .slick-next {
    right: -37px; }
  @media (max-width: 768px) {
    .shooz-brands {
      padding: 50px 0; }
      .shooz-brands .main-label {
        padding-left: 0; }
      .shooz-brands .item {
        height: 264px; }
        .shooz-brands .item__link {
          height: 130px; }
          .shooz-brands .item__link img {
            max-height: 90%; }
      .shooz-brands .slick-next {
        right: -15px; }
      .shooz-brands .slick-prev {
        left: -15px; } }

.shooz-feature-products .main-label {
  margin-bottom: 0.83333em; }

.shooz-feature-products .main-title {
  margin-bottom: 0.8em;
  color: #fbe106; }

.shooz-feature-products__caption {
  padding: 0 8.57143em;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  height: 100%; }

.shooz-feature-products .row-product {
  border: 1px solid #e4e4e4; }

.shooz-feature-products .col-product {
  border-left: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4; }
  .shooz-feature-products .col-product:nth-child(5n) {
    border-left: none; }

.shooz-feature-products .product {
  height: 25.71429em;
  padding: 1.42857em 1.21429em;
  position: relative;
  color: #000000; }
  .shooz-feature-products .product__image {
    max-width: 325px; }
  .shooz-feature-products .product__info {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end;
    padding: 0 2.85714em 1.07143em 2.85714em; }
  .shooz-feature-products .product__name {
    font-size: 1.42857em;
    line-height: 1.2em;
    font-weight: bold; }
  .shooz-feature-products .product__desc {
    line-height: 1.21429em;
    min-height: 2.42857em; }
  .shooz-feature-products .product--highlight {
    background-repeat: no-repeat;
    background-position: top right;
    background-color: #fbe106;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: end;
            align-items: flex-end; }
    .shooz-feature-products .product--highlight .product__image {
      margin: 0 auto; }
    .shooz-feature-products .product--highlight .product__info {
      -webkit-box-align: start;
              align-items: flex-start; }
    .shooz-feature-products .product--highlight .product__detail {
      padding-top: 2.42857em; }

@media screen and (max-width: 1299px) {
  .shooz-feature-products .product__image {
    max-width: 100%; } }

@media (max-width: 1024px) {
  .shooz-feature-products .product__image {
    height: 230px; }
  .shooz-feature-products .product__info {
    position: relative;
    top: auto;
    left: auto;
    height: auto; }
  .shooz-feature-products .product__desc {
    min-height: unset; } }

@media (max-width: 768px) {
  .shooz-feature-products__caption {
    padding: 3.57143em 1.07143em; }
    .shooz-feature-products__caption .main-label {
      padding-left: 0; }
  .shooz-feature-products .link-wrapper {
    display: inline-block;
    width: 100%;
    height: 100%; }
  .shooz-feature-products .product {
    height: auto;
    min-height: 100%; }
    .shooz-feature-products .product--highlight {
      height: 25.71429em; }
    .shooz-feature-products .product__info {
      padding: 0 15px; } }

@media (max-width: 576px) {
  .shooz-feature-products .product__desc {
    display: none; }
  .shooz-feature-products .product__image {
    height: 100px; } }

.shooz-store {
  padding: 5.35714em 0; }
  .shooz-store .main-title {
    margin-bottom: 0.8em; }
  .shooz-store__video {
    position: relative; }
    .shooz-store__video img {
      position: relative;
      z-index: 12; }
  .shooz-store__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: -10px;
    left: -10px;
    background-color: transparent;
    -webkit-transition: 0.7s ease-in;
    transition: 0.7s ease-in;
    opacity: 1;
    border: 3px solid #080a09;
    z-index: 11; }
  .shooz-store__caption {
    padding-left: 5.71429em; }
  .shooz-store__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    background: none;
    outline: none;
    z-index: 15;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 5.78571em;
    height: 5.78571em;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    -webkit-box-align: center;
            align-items: center;
    border-radius: 50%;
    background-color: rgba(251, 250, 242, 0.5); }
    .shooz-store__btn svg {
      position: relative;
      left: 3px; }
  @media (max-width: 768px) {
    .shooz-store {
      padding: 3.57143em 0; }
      .shooz-store__caption {
        padding-left: 0;
        margin-top: 30px; } }

.gosumo-about {
  padding: 6.28571em 0 7.14286em 0; }
  .gosumo-about .main-label {
    margin-bottom: 3em; }
  .gosumo-about .main-title {
    margin-bottom: 0.35714em; }
  .gosumo-about__caption {
    padding-left: 10.28571em;
    padding-right: 3.07143em;
    background-color: #ffffff;
    position: relative;
    right: -2.07143em;
    z-index: 5; }
  .gosumo-about__desc p {
    margin-bottom: 0;
    padding-bottom: 1.42857em;
    color: #6C6C6C; }
  .gosumo-about__image {
    max-width: 100%;
    display: inline-block;
    position: relative; }
    .gosumo-about__image img {
      position: relative; }
    .gosumo-about__image::before {
      content: '';
      width: 64.76378%;
      height: 79.5053%;
      position: absolute;
      top: -1.07143em;
      right: -1.07143em;
      background-color: #F3B612; }
  @media (max-width: 991px) {
    .gosumo-about__caption {
      padding-left: 0; } }
  @media (max-width: 768px) {
    .gosumo-about {
      padding: 50px 0; }
      .gosumo-about__caption {
        padding: 0;
        right: auto;
        background-color: transparent; }
      .gosumo-about__image {
        margin-top: 30px; } }

.gosumo-brands {
  background-color: #212121;
  padding-bottom: 7.42857em;
  padding-top: 5.78571em; }
  .gosumo-brands__list .slick-track {
    border-top: 0.75px solid #434343;
    border-left: none; }
  .gosumo-brands__list .slick-list {
    border-left: 0.75px solid #434343;
    border-right: 0.75px solid #434343; }
  .gosumo-brands .main-label {
    margin-bottom: 2.08333em; }
  .gosumo-brands .main-title {
    color: #ffffff;
    margin-bottom: 0.83929em; }
  .gosumo-brands .flip_card {
    height: 12.5em; }
    .gosumo-brands .flip_card__front {
      background-color: #212121; }
  .gosumo-brands .item {
    border-right: 0.75px solid #434343;
    border-bottom: 0.75px solid #434343;
    padding: 1px; }
    .gosumo-brands .item .flip_card {
      border-top: 1px solid #434343; }
      .gosumo-brands .item .flip_card:first-child {
        border-top: none; }
  .gosumo-brands .slick-prev {
    left: -37px; }
  .gosumo-brands .slick-next {
    right: -37px; }
  @media (max-width: 991px) {
    .gosumo-brands .slick-arrow {
      display: none !important; } }
  @media (max-width: 768px) {
    .gosumo-brands {
      padding: 50px 0; } }

.gosumo-products {
  overflow: hidden;
  padding-bottom: 6.28571em; }
  .gosumo-products__list {
    border-top: 0.75px solid #E4E4E4;
    border-left: 0.75px solid #E4E4E4;
    margin-bottom: 4em; }
  .gosumo-products .item {
    height: 25.71429em;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    padding: 0 2.14286em;
    border-right: 0.75px solid #E4E4E4;
    border-bottom: 0.75px solid #E4E4E4;
    padding: 1px; }
    .gosumo-products .item__image {
      max-width: 100%;
      max-height: 100%;
      width: auto; }
    .gosumo-products .item__brand {
      font-weight: bold;
      font-size: 1.42857em;
      line-height: 1.2em;
      color: #000000;
      margin-bottom: 4px; }
    .gosumo-products .item__desc {
      line-height: 1.21429em;
      color: #212121;
      margin-bottom: 0;
      padding-bottom: 1.42857em; }
    .gosumo-products .item__caption {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-box-align: end;
              align-items: flex-end;
      display: -webkit-box;
      display: flex;
      padding: 0 2.14286em; }
    .gosumo-products .item__badge {
      position: absolute;
      top: -1px;
      right: 1.38889em;
      color: #ffffff;
      font-size: 1.28571em;
      line-height: 1.22222em;
      width: 2.77778em; }
      .gosumo-products .item__badge__icon {
        width: 100%;
        height: auto; }
      .gosumo-products .item__badge__text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        -webkit-box-pack: center;
                justify-content: center;
        font-weight: bold;
        padding-bottom: 0.66667em; }
    .gosumo-products .item--highlight {
      background-color: #F3B612;
      color: #ffffff; }
      .gosumo-products .item--highlight .main-label {
        color: #ffffff;
        margin-bottom: 2.08333em; }
        .gosumo-products .item--highlight .main-label::before {
          background-color: #ffffff; }
      .gosumo-products .item--highlight .item__title {
        font-weight: bold;
        font-size: 4.57143em;
        line-height: 1em;
        font-family: "DINCondensed-Bold"; }
      .gosumo-products .item--highlight .item__caption {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
                align-items: center;
        padding-left: 32.25806%; }
  @media (max-width: 768px) {
    .gosumo-products .item--highlight .item__caption {
      padding: 0 1.07143em; } }

.single {
  padding-top: 6.14286em; }
  .single__layout {
    display: -webkit-box;
    display: flex; }
  .single__content {
    width: calc(100% - 295px);
    padding-right: 6.5em; }
  .single__related {
    width: 295px; }
  .single__share {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
            align-items: center;
    padding: 1.57143em 0 5.71429em 0;
    padding-bottom: 20px; }
  .single__share-title {
    font-size: 2.14286em;
    line-height: 1em;
    font-family: "DINCondensed-Bold";
    color: #212121;
    position: relative;
    top: 5px; }
  .single__tag {
    text-align: right; }
  .single .social {
    margin-bottom: 0; }
    .single .social .item {
      display: inline-block;
      margin-left: 1.07143em; }
      .single .social .item__link {
        color: #212121; }
        .single .social .item__link:hover {
          opacity: .8; }
  .single .date {
    font-size: 0.85714em;
    line-height: 1.33333em;
    letter-spacing: 0.12em;
    color: #212121;
    font-family: "Open Sans"; }
  .single .author {
    font-weight: bold; }
  .single .editor {
    font-family: "Open Sans";
    font-size: 1.07143em;
    line-height: 1.71429em;
    color: #212121;
    padding-top: 1.4em;
    text-align: justify; }
    .single .editor p,
    .single .editor img {
      margin-bottom: 2.26667em; }
  .single .related-list__title {
    font-size: 2.85714em;
    line-height: 1em;
    font-family: "DINCondensed-Bold";
    color: #212121;
    margin-bottom: 0.35em; }
  .single .related-list .item {
    margin-bottom: 2.85714em; }
    .single .related-list .item__image {
      display: inline-block;
      line-height: 0;
      margin-bottom: 1.85714em;
      width: 100%; }
      .single .related-list .item__image img {
        width: 100%; }
    .single .related-list .item__title {
      font-size: 1.57143em;
      line-height: 1em;
      letter-spacing: 0.02em;
      color: #212121;
      font-weight: bold;
      margin-bottom: 0.95455em; }
    .single .related-list .item__link {
      color: #212121; }
      .single .related-list .item__link:hover {
        color: #F3B612; }
    .single .related-list .item__more {
      font-size: 1em;
      line-height: 1.28571em;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: #212121;
      font-weight: bold;
      position: relative;
      display: inline-block;
      padding-bottom: 0.85714em; }
      .single .related-list .item__more::after {
        content: '';
        width: 5.07143em;
        height: 1px;
        background-color: #212121;
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
      .single .related-list .item__more:hover:after {
        width: 100%; }
  .single .copy-container {
    opacity: 0; }
    .single .copy-container #inviteCode.invite-page {
      box-sizing: border-box;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
              flex-direction: row;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      -webkit-box-pack: justify;
              justify-content: space-between;
      width: 100%;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07); }
      .single .copy-container #inviteCode.invite-page #link {
        align-self: center;
        font-size: 1.2em;
        color: #333;
        font-weight: bold;
        -webkit-box-flex: 2;
                flex-grow: 2;
        background-color: #fff;
        border: none; }
      .single .copy-container #inviteCode.invite-page #copy {
        width: 30px;
        height: 30px;
        margin-left: 20px;
        border: 1px solid black;
        border-radius: 5px;
        background-color: #f8f8f8; }
        .single .copy-container #inviteCode.invite-page #copy i {
          display: block;
          line-height: 30px;
          position: relative; }
          .single .copy-container #inviteCode.invite-page #copy i::before {
            display: block;
            width: 15px;
            margin: 0 auto; }
          .single .copy-container #inviteCode.invite-page #copy i.copied::after {
            position: absolute;
            top: 0px;
            right: 35px;
            height: 30px;
            line-height: 25px;
            display: block;
            content: "copied";
            font-size: 1.5em;
            padding: 2px 10px;
            color: #fff;
            background-color: #4099FF;
            border-radius: 3px;
            opacity: 1;
            will-change: opacity, transform;
            -webkit-animation: showcopied 1.5s ease;
                    animation: showcopied 1.5s ease; }
          .single .copy-container #inviteCode.invite-page #copy i img {
            margin-left: auto;
            margin-right: auto;
            padding-bottom: 5px; }
        .single .copy-container #inviteCode.invite-page #copy:hover {
          cursor: pointer;
          background-color: #dfdfdf;
          -webkit-transition: background-color .3s ease-in;
          transition: background-color .3s ease-in; }

@-webkit-keyframes showcopied {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  70% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0; } }

@keyframes showcopied {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  70% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  100% {
    opacity: 0; } }
  @media (max-width: 991px) {
    .single__layout {
      flex-wrap: wrap; }
    .single__content, .single__related {
      width: 100%; }
    .single__content {
      padding-right: 0; }
    .single .related-list {
      overflow: hidden; }
      .single .related-list__inner {
        display: -webkit-box;
        display: flex;
        margin: 0 -7px;
        flex-wrap: wrap; }
      .single .related-list .item {
        width: 50%;
        padding: 0 7px; } }
  @media (max-width: 576px) {
    .single .related-list .item {
      width: 100%; } }

.connect-with-us__page-single {
  padding-top: 0; }

.careers {
  padding-top: 6.35714em; }
  .careers .main-title-page {
    margin-bottom: 0.24167em; }
  .careers .careers-about {
    padding-bottom: 8.21429em; }
    .careers .careers-about .main-label {
      margin-bottom: 2em; }
      .careers .careers-about .main-label::before {
        width: 7.75em;
        left: -10.41667em; }
    .careers .careers-about .main-title {
      margin-bottom: 0.60714em; }
    .careers .careers-about__inner {
      position: relative; }
    .careers .careers-about__image {
      width: 77.75%; }
    .careers .careers-about__caption {
      width: 46.66667%;
      padding: 4.57143em 5em 2.07143em 6.42857em;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 5;
      background-color: #ffffff; }
    .careers .careers-about__desc {
      color: #6c6c6c;
      margin-bottom: 2.85714em; }
      .careers .careers-about__desc p {
        margin-bottom: 0;
        margin-top: 1.64286em; }
        .careers .careers-about__desc p:first-child {
          margin-top: 0; }
  .careers .careers-why {
    background-color: #1D1D1D;
    color: #ffffff;
    text-align: right;
    padding: 5.92857em 0 8.92857em 0; }
    .careers .careers-why__content {
      padding-right: 5.85714em;
      position: relative;
      z-index: 5; }
    .careers .careers-why__image {
      height: 562px;
      position: relative; }
      .careers .careers-why__image img {
        height: 100%;
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .careers .careers-why__image-part {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
      .careers .careers-why__image-part.active {
        opacity: 1;
        z-index: 5; }
    .careers .careers-why .main-title {
      color: #ffffff;
      margin-bottom: 0.21429em; }
    .careers .careers-why .main-label {
      display: inline-block;
      margin-bottom: 2.16667em; }
      .careers .careers-why .main-label::before {
        width: 11.66667em;
        left: auto;
        right: -12.83333em; }
    .careers .careers-why .number {
      font-weight: bold;
      font-size: 6.42857em;
      line-height: 1.2em;
      opacity: 0.05;
      font-family: "DINCondensed-Bold";
      margin-top: 0.56667em;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
    .careers .careers-why .text {
      font-weight: 600;
      font-size: 1.35714em;
      line-height: 1.68421em;
      letter-spacing: 0.02em;
      margin-top: -1.57895em; }
    .careers .careers-why .desc {
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease; }
    .careers .careers-why .item {
      height: 140px;
      position: relative; }
      .careers .careers-why .item:hover .number {
        opacity: 0; }
      .careers .careers-why .item:hover .caption {
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      .careers .careers-why .item:hover .desc {
        opacity: 1; }
    .careers .careers-why .caption {
      position: absolute;
      top: 7.64286em;
      left: 0;
      width: 100%;
      -webkit-transition: all 0.5s ease;
      transition: all 0.5s ease; }
  .careers .careers-jobs {
    background-color: #f5f6f8;
    padding-top: 5.85714em;
    padding-bottom: 5em; }
    .careers .careers-jobs .main-label {
      margin-bottom: 1.78571em; }
    .careers .careers-jobs .main-btn {
      margin-top: 3.21429em; }
    .careers .careers-jobs .item {
      background-color: #ffffff;
      color: #111111;
      border-bottom: 2px solid #000000;
      padding: 1.92857em 2.14286em 2.5em 2.14286em;
      margin-top: 2.14286em;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
      background-color: #ffffff; }
      .careers .careers-jobs .item__link {
        color: #111111; }
      .careers .careers-jobs .item__title {
        font-size: 1.42857em;
        line-height: 1.15em;
        font-weight: bold;
        margin-bottom: 1.05em;
        min-height: 2.2em; }
      .careers .careers-jobs .item__caption {
        font-size: 1.07143em;
        line-height: 1.73333em;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding-top: 1.28571em; }
      .careers .careers-jobs .item__row {
        display: -webkit-box;
        display: flex;
        margin-bottom: 0;
        margin-top: 5px; }
        .careers .careers-jobs .item__row:first-child {
          margin-top: 0; }
      .careers .careers-jobs .item__col {
        display: inline-block; }
        .careers .careers-jobs .item__col--label {
          font-weight: 600;
          width: 90px; }
        .careers .careers-jobs .item__col--desc {
          width: calc(100% - 90px); }
      .careers .careers-jobs .item:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-color: #F3B612; }
        .careers .careers-jobs .item:hover .item__link {
          color: #F3B612; }
    .careers .careers-jobs .jobs__head {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
              justify-content: space-between;
      margin-bottom: 1.85714em;
      -webkit-box-align: center;
              align-items: center; }
    .careers .careers-jobs .jobs__form {
      display: -webkit-box;
      display: flex; }
    .careers .careers-jobs .jobs .form-group {
      width: 15em;
      border: 1px solid #d3d3d3;
      background-color: #ffffff;
      position: relative;
      margin-left: 0.71429em; }
      .careers .careers-jobs .jobs .form-group:first-child {
        margin-left: 0; }
      .careers .careers-jobs .jobs .form-group .icon {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        right: 1.35714em; }
    .careers .careers-jobs .jobs .form-control {
      border: none;
      font-size: 1.07143em;
      height: 3.46667em;
      color: #111111;
      background: none;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .careers .careers-jobs .jobs .form-control::-ms-expand {
        display: none; }
  @media (max-width: 1200px) {
    .careers .careers-about__caption {
      padding: 2.14286em; } }
  @media (max-width: 991px) {
    .careers .careers-about {
      padding-bottom: 3.57143em; }
      .careers .careers-about .main-label {
        padding-left: 45px; }
        .careers .careers-about .main-label::before {
          width: 30px;
          left: 0; }
      .careers .careers-about__image {
        width: 100%; }
      .careers .careers-about__caption {
        width: 100%;
        padding: 2.14286em 0;
        position: static;
        bottom: auto;
        right: auto; }
    .careers .careers-why {
      padding: 3.57143em 0;
      text-align: left; }
      .careers .careers-why .main-label {
        padding-left: 45px; }
        .careers .careers-why .main-label::before {
          width: 30px;
          left: 0; }
      .careers .careers-why__image {
        -webkit-box-ordinal-group: 0;
                order: -1;
        height: auto; }
      .careers .careers-why__content {
        padding-right: 0;
        margin-top: 2.14286em; }
    .careers .careers-jobs {
      padding: 3.57143em 0; }
      .careers .careers-jobs .jobs__head {
        flex-wrap: wrap; }
      .careers .careers-jobs .jobs__form {
        width: 100%;
        margin-top: 2.14286em; } }

.loadmore-container.hidden {
  display: none; }

input.hidden {
  display: none; }

.job-descriptions {
  padding-top: 6.28571em; }
  .job-descriptions .main-title {
    margin-bottom: 0.55357em;
    position: relative; }
    .job-descriptions .main-title .icon {
      position: absolute;
      top: 9px;
      left: -44px; }
  .job-descriptions__info {
    font-size: 1.21429em;
    line-height: 1.70588em; }
    .job-descriptions__info .col:first-child {
      -webkit-box-flex: 0;
              flex: 0 32.16667%; }
  .job-descriptions__detail {
    margin-top: 2.35714em; }
  .job-descriptions__row {
    padding: 3.5em 0; }
    .job-descriptions__row .col:first-child {
      -webkit-box-flex: 0;
              flex: 0 305px; }
  .job-descriptions__label {
    font-size: 2.85714em;
    line-height: 1em;
    color: #212121;
    font-family: "DINCondensed-Bold"; }
  .job-descriptions__desc {
    font-size: 1.14286em;
    line-height: 1.625em;
    color: #434343; }
    .job-descriptions__desc p {
      margin-bottom: 0;
      margin-top: 0.75em; }
      .job-descriptions__desc p:first-child {
        margin-top: 0; }
    .job-descriptions__desc a {
      font-weight: bold;
      color: #434343; }
    .job-descriptions__desc .main-btn {
      margin-top: 2em; }
  .job-descriptions__row {
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
  .job-descriptions__form .main-btn {
    margin-top: 0; }
  .job-descriptions__form .wpcf7-response-output {
    display: none !important; }
  .job-descriptions .info {
    margin-top: 8px;
    display: -webkit-box;
    display: flex; }
    .job-descriptions .info:first-child {
      margin-top: 0; }
    .job-descriptions .info__label {
      display: inline-block;
      width: 120px;
      font-weight: 600;
      color: #111111; }
    .job-descriptions .info__desc {
      color: #434343;
      width: calc(100% - 120px); }
  @media (max-width: 768px) {
    .job-descriptions .main-title .icon {
      position: relative;
      top: -7px;
      left: auto; }
    .job-descriptions__info, .job-descriptions__row {
      flex-wrap: wrap; }
      .job-descriptions__info .col, .job-descriptions__row .col {
        -webkit-box-flex: 0;
                flex: 0 100%; }
        .job-descriptions__info .col:first-child, .job-descriptions__row .col:first-child {
          -webkit-box-flex: 0;
                  flex: 0 100%; }
    .job-descriptions__row .col {
      margin-top: 2.14286em; }
      .job-descriptions__row .col:first-child {
        margin-top: 0; } }

.job-descriptions__page {
  padding-top: 0; }
